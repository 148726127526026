import theme from "../../themes/default";
const styles = {
    wrapper: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "45%",
        height: "60%",
        transform: "translate(-50%, -50%)",
        flexDirection: "column",
        justifyContent: "center",
        border: `3px solid ${theme.palette.primary.main}`
    },
    title: {
        width: "100%",
        height: "25%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paragraph: {
        fontSize: "2vw", fontWeight: "700", color: "#333333"
    },
    loginForm: {
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "75%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column"
    },
    connect: {
        fontSize: "1.5vw",
        fontWeight: "500",
        color: "#fdfdfd"
    },
    details: {
        "& .MuiOutlinedInput-root": {
            background: "white",
            "& > fieldset": {
                border: "1px solid #797979",
            },
            "& input::placeholder": {
                fontSize: "1.2vw",
                color: " #545353",
                opacity: 1,


            },
            height: "6vh",
            width: "100%"
        }
    },
    submit: {
        fontWeight: 700,
        backgroundColor: "#000000",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#000000"
        },

        height: "6vh",
        fontSize: "1.2vw"
    },

    backButton: {
        boxShadow: theme => theme.customShadows.widget,
        textTransform: "none",
        fontSize: 22,
    },
};

export default styles;