import {
    put,
    call,
    takeLatest,
} from "redux-saga/effects";
import {
    Actions as ApplicationActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";

function* createNewApplication(action) {
    try {
        let payload = action.payload;
        console.log("payload: ", payload);
        var bodyFormData = new FormData();
        for (var key in payload) {
            if (payload.hasOwnProperty(key) && key === "bankAccount") {
                bodyFormData.append(key, JSON.stringify(payload[key]));
            }
            else if (payload.hasOwnProperty(key) && key === "dependent") {
                bodyFormData.append(key, JSON.stringify(payload[key]));
            }
            else if (payload.hasOwnProperty(key) && key === "files") {
                if (payload.files) {
                    const files = payload.files
                    for (var key in files) {
                        bodyFormData.append(key, files[key].file, files[key].name)
                    }
                }

            }
            else if (payload.hasOwnProperty(key)) {
                bodyFormData.append(key, payload[key]);
            }
        }
        yield put(ApplicationActions.NEW_APPLICATION_REQUEST());
        const { data } = yield call(axios.post, `inquiries/create`, bodyFormData);
        yield put(ApplicationActions.NEW_APPLICATION_SUCCESS());

    } catch (err) {
        console.error(err);
        yield put(ApplicationActions.NEW_APPLICATION_FAILURE());
    }

}
function* replicateApplication(action) {
    try {
        const payload = action.payload;
        const { details, oldId } = payload
        yield put(ApplicationActions.EDIT_APPLICATION_REQUEST());
        var bodyFormData = new FormData();
        for (var key in details) {
            if (details.hasOwnProperty(key) && key === "bankAccount") {
                bodyFormData.append(key, JSON.stringify(details[key]));
            }
            else if (details.hasOwnProperty(key) && key === "dependent") {
                bodyFormData.append(key, JSON.stringify(details[key]));
            }
            else if (details.hasOwnProperty(key) && key === "files") {
                if (details.files) {
                    const files = details.files
                    for (var key in files) {
                        bodyFormData.append(key, files[key].file, files[key].name)
                    }
                }

            }
            else if (details.hasOwnProperty(key)) {
                bodyFormData.append(key, details[key]);
            }
        }

            const { data } = yield call(axios.post, `inquiries/replicate/${oldId}`, bodyFormData);
        
        yield put(ApplicationActions.EDIT_APPLICATION_SUCCESS());

    } catch (err) {
        console.error(err);
        yield put(ApplicationActions.EDIT_APPLICATION_FAILURE());
    }

}

function* fetchApplications(action) {
    let { payload} = action;
    try {
        // if (payload.page == 0) {
        yield put(ApplicationActions.FETCH_APPLICATIONS_REQUEST())
        // }
        const { data } = yield call(axios.get,
            `inquiries?page=${payload.page}&take=10${payload.filter}&status[]=${payload.status}`);
        yield put(ApplicationActions.FETCH_APPLICATIONS_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(ApplicationActions.FETCH_APPLICATIONS_FAILURE(err));
    }
}
function* getSingleApplication(action) {
    let { payload} = action;
    try {
        // if (payload.page == 0) {
        yield put(ApplicationActions.SINGLE_APPLICATION_REQUEST())
        // }
        const { data } = yield call(axios.get,
            `/inquiries/${payload}`);
        yield put(ApplicationActions.SINGLE_APPLICATION_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(ApplicationActions.SINGLE_APPLICATION_FAILURE(err));
    }
}

function* editApplication(action) {
    try {
        const payload = action.payload;
        const { details, id } = payload
        yield put(ApplicationActions.EDIT_APPLICATION_REQUEST());
        var bodyFormData = new FormData();
        for (var key in details) {
            if (details.hasOwnProperty(key) && key === "bankAccount") {
                bodyFormData.append(key, JSON.stringify(details[key]));
            }
            else if (details.hasOwnProperty(key) && key === "files") {
                if (details.files) {
                    const files = details.files
                    for (var key in files) {
                        bodyFormData.append(key, files[key].file, files[key].name)
                    }
                }

            }
            else if (details.hasOwnProperty(key)) {
                bodyFormData.append(key, details[key]);
            }
        }

            const { data } = yield call(axios.put, `inquiries/update/${id}`, bodyFormData);
        
        yield put(ApplicationActions.EDIT_APPLICATION_SUCCESS());

    } catch (err) {
        console.error(err);
        yield put(ApplicationActions.EDIT_APPLICATION_FAILURE());
    }

}

function* getPendingMasavApps(action) {
    let { payload} = action;
    try {
        // if (payload.page == 0) {
        yield put(ApplicationActions.FETCH_APPLICATIONS_REQUEST())
        // }
        const { data } = yield call(axios.get,
            `inquiries?${payload.filter}&status[]=pending-masav`);
        yield put(ApplicationActions.FETCH_APPLICATIONS_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(ApplicationActions.FETCH_APPLICATIONS_FAILURE(err));
    }
}


function* exportAppsToMasav(action) {
    let { payload} = action;
    try {

        yield put(ApplicationActions.EXPORT_MASAV_REQUEST())

        let ids = ''
        if (payload && payload.length > 0) {
            payload.forEach(function (item) {
                if (ids) {
                    ids = `${ids}&inquiriesIds[]=${item}`
                }
                else
                    ids = `inquiriesIds[]=${item}`

            })
        }
        const { data } = yield call(axios.get,
            `/inquiries/masav?${ids}`, {
            headers: {
                responseType: 'blob',
            }
        });
        var BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const url = window.URL.createObjectURL(new Blob([BOM, data]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date()
        let ddate = date.getDate() +
            "_" + (date.getMonth() + 1) +
            "_" + date.getFullYear();
        link.setAttribute('download', `ממתינים למסב ${ddate}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        yield put(ApplicationActions.EXPORT_MASAV_SUCCESS())
    } catch (err) {
        console.error(err)
        yield put(ApplicationActions.EXPORT_MASAV_FAILURE(err));
    }
}


const sagas = [
    takeLatest(PagesActions.APPLICATION_FORM_SUBMITTED, createNewApplication),
    takeLatest(PagesActions.APPLICATION_FORM_REPLICATE, replicateApplication),
    takeLatest(PagesActions.APPLICATIONS_PAGE_MOUNTED, fetchApplications),
    takeLatest(PagesActions.SINGLE_APPLICATION_PAGE_MOUNTED, getSingleApplication),
    takeLatest(PagesActions.EDIT_APPLICATION_FORM_SUBMITTED, editApplication),
    takeLatest(PagesActions.PENDING_MASAV_PAGE_MOUNTED, getPendingMasavApps),
    takeLatest(PagesActions.EXPORT_MASAV_SUBMITTED, exportAppsToMasav)
];
export default sagas;
