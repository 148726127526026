
import { Box, Button, CircularProgress } from '@mui/material'
import styles from './style'
import SearchTextField from '../../components/searchTextField/SearchTextField'
import DataTable from '../../components/DataTable'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Users({ onMount, submitSearch, clearSearch, loading, data, filter, changeTab }) {


    let filterSearch = ""
    const [page, setPage] = useState(0);
    const navigate = useNavigate()
    const [sortedTable, setsortedTable] = useState(data);

    const onChangePagination = (newPage) => {
        onMount({ page: newPage, filter: filter })
        setPage(newPage)
    }

    useEffect(() => {
        setsortedTable(data)
    }, [data])

    useEffect(() => {
        onMount({ page: 0, filter: filter })
        changeTab(2)
    }, [])

    useEffect(() => {
        onMount({ page: 0, filter: filter });
    }, [filter])



    const showData = (data) => {

        let showtable = [];
        if (data.length > 0) {
            data.map((item, index) => {

                showtable.push({ ...item });
                showtable[index] = {
                    ...showtable[index],
                    roleLabel: item.role == "admin" ? "מנהל" : item.role == "officer" ? "עובד משרד" : "מגייס כספים"
                }
            })
        }
        return showtable;
    }

    let headers = [
        {
            name: "שם מלא",
            width: "15%",
            table: "name"
        },
        {
            name: "כתובת מייל",
            width: "10%",
            table: "email"
        },
        {
            name: "טלפון",
            width: "10%",
            table: "phone"
        },
        {
            name: "תפקיד",
            width: "10%",
            table: "roleLabel"
        },
    ]


    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={styles.wrap}>
                <Box sx={styles.actions}>
                    <Button
                        variant="contained"
                        disableRipple
                        onClick={() => { navigate("/register") }}
                        sx={styles.createButton}>
                        צור משתמש
                    </Button>
                    <SearchTextField
                        onSearch={() => { submitSearch(filterSearch) }}
                        onEmptySearch={() => { clearSearch() }}
                        width="30%"
                        setSearch={(item) => { filterSearch = item }}
                        placeholder="חיפוש משתמשים"
                    />
                </Box>
                <Box>
                    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {loading ?
                            <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> :
                            <DataTable
                                fdata={sortedTable}
                                showData={showData}
                                headers={headers}
                                onChange={onChangePagination}
                                page={page}
                            />
                        }
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}