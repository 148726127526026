import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const statisticsReducer = (state = {

    error: false,
    isLoading: false,
    monthData:null,
    yearLoading:false,
    yearData:null,
    pieData:null,
    pieLoading:false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.GET_MONTH_STATISTICS_REQUEST:
            newState = { ...state, isLoading: true, error: false }
            break;
        case ActionsTypes.GET_MONTH_STATISTICS_FAILURE:
            newState = { ...state, isLoading: false, error: true }
            break;
        case ActionsTypes.GET_MONTH_STATISTICS_SUCCESS:
            newState = { ...state, isLoading: false, error: false,monthData:payload }
            break;
        case ActionsTypes.GET_YEAR_GRAPH_REQUEST:
            newState = { ...state, yearLoading: true, error: false,yearData:null }
            break;
        case ActionsTypes.GET_YEAR_GRAPH_SUCCESS:
            newState = { ...state, yearLoading: false, error: false,yearData:payload }
            break;
        case ActionsTypes.GET_YEAR_GRAPH_FAILURE:
            newState = { ...state, yearLoading: false, error: true,yearData:null }
            break;
        case PageActionsTypes.DASHBOARD_PAGE_MOUNTED:
            newState = { ...state, isSubmitting: false, error: false }
            break;
            case ActionsTypes.GET_PIE_CHARTS_REQUEST:
                newState = { ...state, pieLoading: true, error: false,pieData:null }
                break;
            case ActionsTypes.GET_PIE_CHARTS_SUCCESS:
                newState = { ...state, pieLoading: false, error: false,pieData:payload }
                break;
            case ActionsTypes.GET_PIE_CHARTS_FAILURE:
                newState = { ...state, pieLoading: false, error: true }
                break;
    

            
        default:
            newState = state;

    }
    return newState;
}
export default statisticsReducer;