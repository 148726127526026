import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const reportsReducer = (state = {


    errorMessage: false,
    isSubmitting: false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.GET_CSV_REQUEST:
            newState = { ...state, isSubmitting: true, errorMessage: false }
            break;
        case ActionsTypes.GET_CSV_FAILURE:
            newState = { ...state, isSubmitting: false, errorMessage: payload }
            break;
        case ActionsTypes.GET_CSV_SUCCESS:
            newState = { ...state, isSubmitting: false, errorMessage: false }
            break;

        case PageActionsTypes.REPORTS_PAGE_MOUNTED:
            newState = { ...state, isSubmitting: false, errorMessage: false }
            break;
        default:
            newState = state;

    }
    return newState;
}
export default reportsReducer;