import { connect } from "../../utils/reduxUtils"
import Component from "./Dashboard"
import { Actions } from "../../pages/actions"
import * as StatisticSelector from "../../modules/statistics/selectors"


const mapDispatchToProps = {
    onMount: Actions.DASHBOARD_PAGE_MOUNTED,
    getYearGraph: Actions.DASHBOARD_NEW_YEAR_SUBMITTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED

};

const mapStateToProps = state => {
    return {
        loading: StatisticSelector.isLoading(state),
        error: StatisticSelector.error(state),
        yearData: StatisticSelector.yearData(state),
        monthData: StatisticSelector.monthData(state),
        pieData: StatisticSelector.pieData(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);