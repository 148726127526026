import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    TableFooter,
    TableContainer
} from '@mui/material'
import styles from './style'
import { useNavigate, useLocation } from 'react-router-dom';



export default function DataTable({ fdata, headers, showData, onChange, page, path }) {

    const location = useLocation()
    const navigate = useNavigate()


    let count = 0
    let emptyRows = 0
    let rowsPerPage = 10;
    const handleChangePage = (event, newPage) => {
        onChange(newPage)
    };
    let dataView = []
    let data = fdata ? fdata.length > 0 ? fdata : [] : []
    if (data[0] && data[0].length > 0) {
        dataView = showData(data[0]);

        count = data[1];
        emptyRows = rowsPerPage - dataView.length
    }
    else {
        count = 0

        emptyRows = 10
    }

    const handleclick = (row) => {
        if (path)
            navigate(`${path}/${row.status}/${row.id}`)
        else
            navigate(`${location.pathname}/${row.id}`, { state: { row } })
    }
    return (
        <TableContainer>
            <Table sx={{ width: "100%", mt: "2%" }}>
                <TableHead >
                    <TableRow
                        sx={{ backgroundColor: "#D4D4D4", borderBottom: "none", }}
                    >
                        {headers.map((item, i) => (
                            <TableCell
                                key={i}
                                sx={{
                                    minWidth: { ...item.width },
                                    borderRadius: i === 0 ? "8px 0px 0px 0px " :
                                        i + 1 === headers.length ? " 0px 8px 0px 0px  " :
                                            {},
                                    ...styles.tabelHeader
                                }}>
                                {item.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {dataView && dataView.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                            onClick={() => { handleclick(row) }}
                        >
                            {headers.map((header, i) => (
                                < TableCell
                                    key={i}
                                    align="left"
                                    sx={styles.tabelCell}>
                                    {row[header.table]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 34 * emptyRows }} >
                            {headers.map((item, i) => (
                                <TableCell
                                    key={i}
                                    sx={styles.cell} />
                            ))}
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter sx={{ height: 'auto !important', p: 1, }}>
                    <TableRow>
                        <TablePagination
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                            count={count}
                            page={count <= 0 ? 0 : page}
                            onPageChange={handleChangePage}
                            align="center"
                            sx={styles.pagination}
                            labelDisplayedRows={({ page }) => {
                                return `${page === 0 ? dataView.length < 10 ? dataView.length : 10 : 10 * (page + 1) > count ? count : 10 * (page + 1)}/${count}`;
                            }}
                            showFirstButton={true}
                            showLastButton={true}
                        />
                    </TableRow>
                </TableFooter>

            </Table>
        </TableContainer >
    )
}