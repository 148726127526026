import queryString from "query-string";
import {
    put,
    call,
    takeLatest,
    fork,
    take,
    cancel,
    delay,
    race,
} from "redux-saga/effects";
import { Sagas } from ".";
import {
    ActionsTypes as AuthActionTypes,
    Actions as UserActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";

// import config from 'config';
import _ from "lodash";


function* registerUser(action) {
    try {
        yield put(UserActions.REGISTER_REQUEST());
        const payload = action.payload;
        const { data } = yield call(axios.post, `auth/register`, {
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            role: payload.role,
        });
        yield put(UserActions.REGISTER_SUCCESS());
    } catch (err) {
        console.error(err);
        yield put(UserActions.REGISTER_FAILURE(err));
    }

}


function* fetchUsers(action) {
    let { payload, type } = action;
    try {
        // if (payload.page == 0) {
        yield put(UserActions.FETCH_USERS_REQUEST())
        // }
        const { data } = yield call(axios.get,
            `/users?page=${payload.page}&take=10${payload.filter}`);
        yield put(UserActions.FETCH_USERS_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(UserActions.FETCH_USERS_FAILURE(err));
    }
}

function* getSingleUser(action) {
    try {
        const { type, payload } = action;
        yield put(UserActions.SINGLE_USER_REQUEST());
        const { data } = yield call(axios.get, `users/${payload}`);
        yield put(UserActions.SINGLE_USER_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(UserActions.SINGLE_USER_FAILURE(err));
    }
}

function* editUser(action) {
    try {
        const { type, payload } = action;
        const details = payload.details
        yield put(UserActions.EDIT_USER_REQUEST());
        const { data } = yield call(axios.put, `users/${payload.id}`, {
            name: details.name,
            phone: details.phone,
            email: details.email,
            role: details.role
        });
        yield put(UserActions.EDIT_USER_SUCCESS());
    } catch (err) {
        console.error(err)
        yield put(UserActions.EDIT_USER_FAILURE(err));
    }
}

function* deleteUser(action) {
    try {
        const { type, payload } = action;
        yield put(UserActions.DELETE_USER_REQUEST());
        const { data } = yield call(axios.delete, `users/${payload}`);
        yield put(UserActions.DELETE_USER_SUCCESS());
    } catch (err) {
        console.error(err)
        yield put(UserActions.DELETE_USER_FAILURE(err));
    }
}


const sagas = [

    takeLatest(PagesActions.REGISTER_FORM_SUBMITTED, registerUser),
    takeLatest(PagesActions.USERS_PAGE_MOUNTED, fetchUsers),
    takeLatest(PagesActions.SINGLE_USER_PAGE_MOUNTED, getSingleUser),
    takeLatest(PagesActions.EDIT_USER_SUBMITTED, editUser),
    takeLatest(PagesActions.DELETE_USER_SUBMITTED, deleteUser),


];
export default sagas;
