import {
    put,
    call,
    takeLatest,
} from "redux-saga/effects";
import { Sagas } from ".";
import {
    ActionsTypes as categotyActionTypes,
    Actions as categoryActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";
import _ from "lodash";


function* getActiveCategories(action) {
    try {
        yield put(categoryActions.ACTIVE_CATEGORY_REQUEST())
        const { data } = yield call(axios.get, `/inquiry-categories`);
        yield put(categoryActions.ACTIVE_CATEGORY_SUCCESS(data))
    }
    catch (err) {
        yield put(categoryActions.ACTIVE_CATEGORY_FAILURE())
    }
}

function* getAllCategories(action) {
    try {
        yield put(categoryActions.ALL_CATEGORY_REQUEST())
        const { data } = yield call(axios.get, `/inquiry-categories/all`);
        yield put(categoryActions.ALL_CATEGORY_SUCCESS(data))
    }
    catch (err) {
        yield put(categoryActions.ALL_CATEGORY_FAILURE())
    }
}

function* getSingleCategory(action) {
    let { payload, type } = action;
    try {
        yield put(categoryActions.SINGLE_CATEGORY_REQUEST())
        const { data } = yield call(axios.get, `/inquiry-categories/${payload}`);
        yield put(categoryActions.SINGLE_CATEGORY_SUCCESS(data))
    }
    catch (err) {
        yield put(categoryActions.SINGLE_CATEGORY_FAILURE())
    }
}

function* editCategory(action) {
    let { payload, type } = action;
    const { id, details } = payload
    try {

        yield put(categoryActions.EDIT_CATEGORY_REQUEST())
        const { data } = yield call(axios.put, `/inquiry-categories/update/${id}`, {
            subject: details.subject,
            subjectEn: details.subjectEn,
            isActive: details.isActive,
            fileTypes: details.fileTypes
        });
        yield put(categoryActions.EDIT_CATEGORY_SUCCESS(data))
    }
    catch (err) {
        yield put(categoryActions.EDIT_CATEGORY_FAILURE())
    }
}

function* createCategory(action) {
    let { payload, type } = action;
    try {

        yield put(categoryActions.CREATE_CATEGORY_REQUEST())
        const { data } = yield call(axios.post, `/inquiry-categories/create`, {
            subject: payload.subject,
            subjectEn: payload.subjectEn,
            isActive: payload.isActive,
            fileTypes: payload.fileTypes
        });
        yield put(categoryActions.CREATE_CATEGORY_SUCCESS(data))
    }
    catch (err) {
        yield put(categoryActions.CREATE_CATEGORY_FAILURE())
    }
}

const sagas = [
    takeLatest(PagesActions.APPLICATION_FORM_MOUNTED, getActiveCategories),
    takeLatest(PagesActions.REPORTS_PAGE_MOUNTED, getAllCategories),
    takeLatest(PagesActions.CATEGORIES_PAGE_MOUNTED, getAllCategories),
    takeLatest(PagesActions.GET_CATEGORY_REQUEST, getSingleCategory),
    takeLatest(PagesActions.EDIT_CATEGORY_FORM_SUBMITTED, editCategory),
    takeLatest(PagesActions.NEW_CATEGORY_SUBMITTED, createCategory),



];
export default sagas;
