import theme from "../../themes/default";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Box, TextField } from "@mui/material";


export default function SearchTextField({ width, onSearch, onEmptySearch, setSearch, placeholder, ...props }) {

    const details = {
        "& .MuiOutlinedInput-root": {
            background: "#ffffff",
            "& > fieldset": {
                border: ` 1px solid ${theme.palette.primary.main}`,
                borderRadius: 0,
            },
            "& input::placeholder": {
                fontSize: "14px",
                fontWeight: 500

            },
            height: 40
        },

    }

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    })

    useEffect(
        () => {
            if (firstUpdate.current == false) {
                if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
                    setSearch(debouncedSearchTerm)
                    onSearch()
                } else if (debouncedSearchTerm.length != 1) {
                    onEmptySearch()
                }
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
    );


    return (
        <Box sx={{ width: width ? width : "45%", mx: 2, pb: 0, ...props }}>
            <TextField
                sx={details}
                variant="outlined"
                placeholder={placeholder ? placeholder : "חיפוש"}
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </Box>
    )
}

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}