import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [
    'LOGIN_REQUEST',
    'LOGIN_SUCCESS',
    'LOGIN_FAILURE',
    'LOGIN_FORM_UNMOUNT',


    'LOGOUT_SUCCESS',

    'REGISTER_REQUEST',

    'RESET_PASSWORD_REQUEST',
    'RESET_PASSWORD_FAILED',

    'REGISTER_REQUEST',
    'REGISTER_SUCCESS',
    'REGISTER_FAILURE',

    'FORGOT_PASSWORD_REQUEST',
    'FORGOT_PASSWORD_SUCCESS',
    'FORGOT_PASSWORD_FAILURE'


]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);