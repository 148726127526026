import theme from "../../themes/default";

const style = {
    details: {
        "& .MuiOutlinedInput-root": {
            background: "#00000005",
            "& > fieldset": {
                border: ` 1px solid ${theme.palette.primary.main}`,
                borderRadius: 0,
            },
            "& input::placeholder": {
                fontSize: "14px",
                fontWeight: 500

            },
            height: 34
        }
    },
    avatar: {
        width: 24,
        height: 24,
        bgcolor: "#ffffff",
        color: "red",

    },
    pic: {
        backgroundPosition: 'center',
        backgroundSize: { md: "cover", xs: "contain" },
        backgroundRepeat: 'no-repeat',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #D4D4D4"

    },
    name: {
        width: "100%",
        mt: "2%",
        "& .MuiOutlinedInput-root": {
            background: "#F4F6F6",
            borderRadius: "12px",
            "& > fieldset": {
                border: "1px solid #D4D4D4",

            },

        },
        "& label": {

            fontWeight: 500,
            // float: "right",
            mt: "2%",

        },
    },
    wrapper: {
        width: "80%", display: "flex", flexDirection: "column"
    },
    buttonDiv: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
        width: "44%"
    },
    button: {
        borderRadius: 0,
        fontSize: "0.9rem",
        fontWeight: "Bold"
    },
    tabelHeader: {
        fontSize: "1.2vw",
        height: 'auto !important',
        py: "1.3vh",
        px: "1vh",
    },
    pagination: {

        width: "100%", backgroundColor: "#D4D4D4", borderRadius: "0px 0px 8px 8px", p: 0, borderBottom: "none",
        ".MuiTablePagination-toolbar": {
            p: "auto",
        },
        ".MuiTablePagination-spacer": {
            dispaly: "none",
            p: 0

        },
        ".MuiTablePagination-actions": {
            mr: "40%",
            p: 0,

        }
    },
    tabelCell: {
        backgroundColor: "#F7F7F7",
        borderBottom: "1px solid white",
        py: "1vh",
        px: "1vw",
        height: "4vh",
        fontSize: "1vw",
        fontWeight: 600
    },
    cell: {
        backgroundColor: "#F7F7F7",
        borderBottom: "1px solid white",
        py: 1,
        px: 1,
        fontSize: "0.7rem",
        fontWeight: 600
    }
}





export default style;