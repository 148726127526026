import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [
    'NEW_APPLICATION_REQUEST',
    'NEW_APPLICATION_SUCCESS',
    'NEW_APPLICATION_FAILURE',

    'FETCH_APPLICATIONS_REQUEST',
    'FETCH_APPLICATIONS_SUCCESS',
    'FETCH_APPLICATIONS_FAILURE',

    'SINGLE_APPLICATION_REQUEST',
    'SINGLE_APPLICATION_SUCCESS',
    'SINGLE_APPLICATION_FAILURE',

    'EDIT_APPLICATION_REQUEST',
    'EDIT_APPLICATION_SUCCESS',
    'EDIT_APPLICATION_FAILURE',

    'EXPORT_MASAV_REQUEST',
    'EXPORT_MASAV_SUCCESS',
    'EXPORT_MASAV_FAILURE'



]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);