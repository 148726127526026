import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const categoryReducer = (state = {
    error: false,
    loading: false,
    activeCategories: [],
    categories: [],
    submitSuccess: false,
    category: null,
    isSubmitting: false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.ACTIVE_CATEGORY_REQUEST:
            newState = { ...state, loading: true }
            break;
        case ActionsTypes.ACTIVE_CATEGORY_SUCCESS:
            newState = { ...state, loading: false, activeCategories: payload }
            break;
        case ActionsTypes.ACTIVE_CATEGORY_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.ALL_CATEGORY_REQUEST:
            newState = { ...state, loading: true }
            break;
        case ActionsTypes.ALL_CATEGORY_SUCCESS:
            newState = { ...state, loading: false, categories: payload }
            break;
        case ActionsTypes.ALL_CATEGORY_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.SINGLE_CATEGORY_REQUEST:
            newState = { ...state, loading: true }
            break;
        case ActionsTypes.SINGLE_CATEGORY_SUCCESS:
            newState = { ...state, loading: false, category: payload }
            break;
        case ActionsTypes.SINGLE_CATEGORY_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.EDIT_CATEGORY_REQUEST:
            newState = { ...state, isSubmitting: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.EDIT_CATEGORY_SUCCESS:
            newState = { ...state, isSubmitting: false, submitSuccess: true, error: false }
            break;
        case ActionsTypes.EDIT_CATEGORY_FAILURE:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: true }
            break;
        case ActionsTypes.CREATE_CATEGORY_REQUEST:
            newState = { ...state, isSubmitting: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.CREATE_CATEGORY_SUCCESS:
            newState = { ...state, isSubmitting: false, submitSuccess: true, error: false }
            break;
        case ActionsTypes.CREATE_CATEGORY_FAILURE:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: true }
            break;
        case PageActionsTypes.SINGLE_CATEGORY_PAGE_MOUNTED:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: false, category: null }
            break;
        default:
            newState = state;

    }
    return newState;
}
export default categoryReducer;