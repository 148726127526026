import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.statistics;
export const monthData = createSelector(sliceSelector, slice => slice.monthData)
export const error = createSelector(sliceSelector, slice => slice.error);
export const isLoading = createSelector(sliceSelector, slice => slice.isLoading);
export const yearData = createSelector(sliceSelector, slice => slice.yearData);
export const pieData = createSelector(sliceSelector, slice => slice.pieData);


