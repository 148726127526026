import { connect } from "../../utils/reduxUtils"
import Component from "./SideBar"
import * as AuthSelectors from "../../modules/auth/selectors"
import { Actions } from "../../pages/actions"


const mapDispatchToProps = {
    onLogout: Actions.SIDEBAR_LOGOUT_REQUESTED
};

const mapStateToProps = state => {
    return {
        user: AuthSelectors.user(state),
        name: sessionStorage.getItem("userName"),
        activeTab: AuthSelectors.activeTab(state)


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);