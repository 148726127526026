import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const authReducer = (state = {
    isLoggedIn: false,
    user: null,
    error: false,
    //what is the difference between isLoggedin?
    authComplete: false,
    loading: false,
    loginFailureMessage: false,
    isValidate: false,
    name: '',
    activeTab: 1,
    submitSuccess: false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.LOGIN_SUCCESS:
            newState = { ...state, isLoggedIn: true, loading: false, user: payload.user, name: payload.name, authComplete: true }
            break;
        case ActionsTypes.LOGIN_REQUEST:
            newState = { ...state, loading: true, loginFailureMessage: false }
            break;
        case ActionsTypes.LOGIN_FAILURE:
            newState = { ...state, isLoggedIn: false, loading: false, loginFailureMessage: payload }
            break;
        case ActionsTypes.REGISTER_REQUEST:
            newState = { ...state, loading: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.REGISTER_SUCCESS:
            newState = { ...state, loading: false, submitSuccess: true }
            break;
        case ActionsTypes.REGISTER_FAILURE:
            newState = { ...state, loading: false, error: true }
            break;
        case ActionsTypes.LOGOUT_SUCCESS:
            newState = { ...state, isLoggedIn: false, authComplete: true };
            break;
        case ActionsTypes.LOGIN_FORM_UNMOUNT:
            newState = { ...state, loginFailureMessage: false };
            break;
        case ActionsTypes.RESET_PASSWORD_FAILED:
            newState = { ...state, error: true };
            break;
        case ActionsTypes.RESET_PASSWORD_REQUEST:
            newState = { ...state, error: false };
            break;
        case PageActionsTypes.ACTIVE_TAB_CHANGED:
            newState = { ...state, activeTab: payload };
            break;
        case ActionsTypes.FORGOT_PASSWORD_REQUEST:
            newState = { ...state, loading: true, error: false, submitSuccess: false };
            break;
        case ActionsTypes.FORGOT_PASSWORD_SUCCESS:
            newState = { ...state, loading: false, submitSuccess: true };
            break;
        case ActionsTypes.FORGOT_PASSWORD_FAILURE:
            newState = { ...state, loading: false, submitSuccess: false, error: true };
            break;
        case PageActionsTypes.FORGOT_PASSWORD_PAGE_MOUNTED:
            newState = { ...state, error: false, submitSuccess: false };
            break;
        default:
            newState = state;

    }
    return newState;
}
export default authReducer;