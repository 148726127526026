import React, { useEffect, useState } from 'react'
import {
    Box, TextField,
    Button,
    Autocomplete,
    FormControl,
    FormHelperText,
    CircularProgress,
    Snackbar, Alert,
    FormControlLabel, RadioGroup, Radio,
    InputLabel, Select, MenuItem
} from '@mui/material'
import { useFormik } from 'formik'
import styles from './style'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fetchCities, fetchBankCodes, fetchCategories } from '../../utils/autoComplete'
import {useParams } from 'react-router-dom'
import moment from 'moment'
import Modal from "react-modal";


Modal.setAppElement("#root");

export const  SingleApplication=React.forwardRef(( props,ref)=>{
    const {onMount, onSubmitCreate, onSubmitUpdate, loading, error,
    submitSuccess, application,
    changeTab, isSubmitting}=props
    // assignment
    const [cities, setCities] = useState([])
    const [codes, setCodes] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [openSnack, setOpenSnack] = useState(false)
    const [files, setFiles] = useState()
    // const navigate = useNavigate()
    const [decisionDate, setDecisionDate] = useState("")
    const [value, setValue] = useState("")
    const { id } = useParams();
    const { status } = useParams();
    const role = sessionStorage.getItem("userRole")
    const [target, setTarget] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility




    // useEffects
    useEffect(() => {
        fetchCities().then(c => setCities(c)).catch(console.error);
        fetchBankCodes().then(c => setCodes(c)).catch(console.error);
        fetchCategories().then(c => setCategories(c)).catch(console.error)

    }, [])
    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    useEffect(() => {
        onMount(id)
    }, [id,onMount])

    useEffect(() => {
        if (status) {
            switch (status) {
                case "pending":
                    changeTab(4);
                    break;
                case "approved":
                    changeTab(5);
                    break;
                case "pending-masav":
                    changeTab(6);
                    break;
                case "finished":
                    changeTab(7);
                    break;
                case "rejected":
                    changeTab(8);
                    break;
                    default:
                        changeTab(4);
                        break;
            }
        }
    }, [status,changeTab])

    useEffect(() => {
        if (application) {
            setValue(application.status)
            console.log("application: ", application);
            if (application.decisionDate) {
                let date = new Date(application.decisionDate)
                let year = date.getFullYear();
                let month = `${date.getMonth() + 1}`.padStart(2, "0");
                let day = `${date.getDate()}`.padStart(2, "0")
                const ddate = [year, month, day].join("-")
                setDecisionDate(ddate)
            }
            if (application.category && application.category.fileTypes && application.category.fileTypes.length > 0) {
                if (application.files && application.files.length > 0) {
                    let appfiles = {}
                    application.files.forEach(function (item) {
                        appfiles = { ...appfiles, [item.type.guid]: { url: item.url, id: item.type.id } }
                    })

                    setFiles(appfiles)
                }

            }
        }
    }, [application])

    const handleChange = (e) => {

        setValue(e.target.value)


    }

    const details = [
        {
            name: "שם פרטי",
            id: "firstName"
        },
        {
            name: "שם משפחה",
            id: "lastName"
        },
        {
            name: 'ת.ז',
            id: "tz_num"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "כתובת מייל",
            id: "email"
        },
        {
            name: "עיר",
            id: "city"
        },
        {
            name: "רחוב",
            id: "street"
        },
        {
            name: "מס' בית",
            id: "houseNo"
        },
        {
            name: "קטגוריה",
            id: "category"
        },
        {
            name: "נושא הפניה",
            id: "subject"
        },

    ]

    const bankDetails = [
        {
            name: "בנק",
            id: "bankName",
            width: "25%"
        },
        {
            name: "מספר בנק",
            id: "code",
            width: "13%"
        },
        {
            name: "מספר חשבון",
            id: "accountNumber",
            width: "20%"
        },
        {
            name: "סניף",
            id: "branch",
            width: "10%"
        },
        {
            name: 'ע"ש',
            id: "ownerName",
            width: "20%"
        },
    ]

    const committee = [
        {
            name: "תאריך החלטה",
            id: "decisionDate",
            width: "25%"
        },
        {
            name: "סכום",
            id: "decisionAmount",
            width: "25%"
        },
        {
            name: "אמצעי תשלום",
            id: "paymentMethod",
            width: "25%"
        }
    ]
    const handleselectedFile = (event) => {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            formik.setFieldValue(event.target.id, {
                url: file,
                file: event.target.files[0],
                name: event.target.files[0].name,
            })
            let appfiles = files;
            if (appfiles.hasOwnProperty(event.target.id)) {
                delete appfiles[event.target.id]
                setFiles(appfiles)
            }

        }
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const validateInputs = (values) => {
        const errors = {};
        if (!values.subject) {
            errors.subject = "יש להזין נושא לפניה";
        }
        if (!values.bankName) {
            errors.bankName = "יש להזין שם בנק";
        }
        if (!values.code) {
            errors.code = "יש להזין קוד בנק";
        }
        if (!values.accountNumber) {
            errors.accountNumber = "יש להזין מס' חשבון";
        }
        else if (values.accountNumber && !/^[0-9]*$/.test(values.accountNumber)) {
            errors.accountNumber = "מספרים בלבד";
        }
        if (!values.branch) {
            errors.branch = "יש להזין מס' סניף";
        }
        else if (values.branch && !/^[0-9]*$/.test(values.branch)) {
            errors.branch = "מספרים בלבד";
        }
        if (!values.ownerName) {
            errors.ownerName = "יש להזין שם בעל החשבון' ";
        }
        if (value !== "pending") {
            if (!values.decisionDate) {
                errors.decisionDate = "נא להזין תאריך";
            }
            else if (moment(values.decisionDate).isAfter(moment())) {
                errors.decisionDate = "תאריך עתידי";
            }
        }
        if (value !== "pending" && value !== "rejected") {
            if (!values.decisionAmount) {
                errors.decisionAmount = "נא להזין סכום";
            }
            if (values.decisionAmount && !/^[0-9]*$/.test(values.decisionAmount)) {
                errors.decisionAmount = "מספרים בלבד";
            }
            if (!values.paymentMethod) {
                errors.paymentMethod = "נא לבחור צורת תשלום";
            }
        }
        return errors;
    }
    const formik = useFormik({
        initialValues: application ? {
            firstName: application.dependent.firstName,
            lastName: application.dependent.lastName,
            email: application.dependent.email,
            phone: application.dependent.phone,
            tz_num: application.dependent.tz_num,
            city: { שם_ישוב: application.dependent.city },
            street: application.dependent.street,
            houseNo: application.dependent.houseNo,
            category: application.category,
            subject: application.description,
            bankName: codes.find(t => t.code === application.bankAccount.code),
            code: application.bankAccount.code,
            branch: application.bankAccount.branch,
            accountNumber: application.bankAccount.accountNumber,
            ownerName: application.bankAccount.ownerName,
            decisionDate: decisionDate,
            decisionAmount: application.decisionAmount ? application.decisionAmount : '',
            paymentMethod: application.paymentMethod ? application.paymentMethod : '',

        } : {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            tz_num: '',
            city: null,
            street: '',
            houseNo: '',
            category: null,
            subject: '',
            bankName: null,
            code: '',
            branch: '',
            accountNumber: '',
            ownerName: '',
            decisionDate: '',
            decisionAmount: '',
            paymentMethod: 0,


        },
        validate: validateInputs,
        enableReinitialize: true,
        onSubmit: (values) => {
            let files = {}
            console.log(values.category);
            console.log(values.category.fileTypes.length);

            if (values.category.fileTypes && values.category.fileTypes.length > 0) {
                values.category.fileTypes.forEach(function (item) {
                    if (values[item.guid]) {
                        files = { ...files, [item.guid]: values[item.guid] }
                    }

                })
            }
            let details;
            if(target === 1)
            {
                details = {
                    category: values.category.id,
                    description: values.subject,
                    dependent: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phone: values.phone,
                        email: values.email,
                        tz_num: values.tz_num,
                        city: values.city['שם_ישוב'],
                        street: values.street,
                        houseNo: Number(values.houseNo)
                    },
                    bankAccount: {
                        code: values.code,
                        branch: values.branch,
                        accountNumber: values.accountNumber,
                        ownerName: values.ownerName
                    },
                    files: files
                }
                onSubmitCreate({oldId: id, details: details})
            }
            else{
                console.log("in the update");
                
                let decisionDetails = value !== 'pending' && value !== 'rejected' ? {
                    decisionDate: values.decisionDate,
                    decisionAmount: values.decisionAmount,
                    paymentMethod: values.paymentMethod
                } : value === 'rejected' ? {
                    decisionDate: values.decisionDate,
                } : {};
    
                details = {
                    bankAccount: {
                        code: values.code,
                        branch: values.branch,
                        accountNumber: values.accountNumber,
                        ownerName: values.ownerName
                    },
                    status: value,
                    description: values.subject,
                    ...decisionDetails,
                    files: files
                }
                onSubmitUpdate({ id: id, details: details})
            }
            
        },
    });

    useEffect(() => {
        if (formik.values.bankName)
            formik.setFieldValue("code", formik.values.bankName.code);
        else {
            formik.setFieldValue("code", "");
        }
    }, [formik.values.bankName]);


    return (
        <>
            <Box sx={styles.main} 
>
                {loading ? <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> :
                <Box sx={{width:"100%",display:"flex",justifyContent:"center"}} ref={ref}>
            
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        id="applicationForm"
                        sx={styles.form}>
                        <Box sx={styles.title}>
                            {application ?
                                `טופס פניה-${application.dependent.firstName} ${application.dependent.lastName}` :
                                "טופס פניה"}
                        </Box>
                        <Box sx={styles.formDetails}>
                            {details.map((item) => {
                                if (item.id === "city")
                                    return (< React.Fragment key={item.id} >
                                        <FormControl sx={styles.Autodetails}>
                                            <Autocomplete
                                                id={item.id}
                                                name={item.id}
                                                value={formik.values[item.id]}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("city", value)

                                                }}
                                                options={cities}
                                                loading={true}
                                                disabled={true}
                                                loadingText="אין אופציות"
                                                getOptionLabel={type => type['שם_ישוב']}
                                                isOptionEqualToValue={(option, value) => option['שם_ישוב'] === value['שם_ישוב']}
                                                renderInput={(params) => <TextField {...params}
                                                    placeholder={item.name}
                                                    sx={styles.placeholder}
                                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                                />}
                                            />
                                        </FormControl>
                                    </React.Fragment>);
                                else if (item.id === "category")
                                    return (< React.Fragment key={item.id} >
                                        <FormControl sx={styles.Autodetails}>
                                            <Autocomplete
                                                id={item.id}
                                                name={item.id}
                                                value={formik.values[item.id]}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("category", value)
                                                    setSelectedCategory(value);
                                                    if (formik.values.category) {
                                                        formik.setFieldValue("category", null)
                                                    }
                                                }}
                                                options={categories}
                                                loading={true}
                                                disabled={true}
                                                loadingText="אין אופציות"
                                                getOptionLabel={type => type['subject']}
                                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                                renderInput={(params) => <TextField {...params}
                                                    placeholder={item.name}
                                                    sx={styles.placeholder}
                                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                                />}
                                            />
                                        </FormControl>
                                    </React.Fragment>);

                                return (
                                    <TextField
                                        key={item.id}
                                        sx={{
                                            ...styles.details,
                                            mt: 6,
                                            width: item.id === "subject" ? "100%" : "23%"
                                        }}
                                        rows={item.id === "subject" ? 5 : 1}
                                        multiline={item.id === "subject" ? true : false}
                                        id={item.id}
                                        name={item.id}
                                        variant="outlined"
                                        // type={item.id}
                                        disabled={item.id === "subject" ? false : true}
                                        label={item.name}
                                        fullWidth
                                        value={formik.values[item.id]}
                                        onChange={formik.handleChange}
                                        error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                        helperText={formik.touched[item.id] && formik.errors[item.id]}
                                    />
                                )
                            })}
                            <Box component="p" sx={styles.p}>
                                קבצים
                            </Box>
                        </Box>
                        <Box sx={styles.filesBox}>
                            {application && application.category.fileTypes.length > 0 &&
                                application.category.fileTypes.map((item) => (
                                    <Box sx={{ display: "flex", my: 2 }} key={item.guid}>
                                        <input
                                            key={item.guid}
                                            type="file"
                                            name={item.guid}
                                            id={item.guid}
                                            onChange={handleselectedFile}
                                            style={{ display: "none" }}
                                        />
                                        <label htmlFor={item.guid}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                endIcon={<UploadFileIcon />}
                                            >
                                                {item.label}
                                            </Button>
                                        </label>

                                        {files && files[item.guid] && files[item.guid].url ? <Button
                                            onClick={() => {
                                                window.open(files[item.guid].url, '_blank', 'noopener,noreferrer');
                                            }}
                                        >
                                            הצג קובץ
                                        </Button> :

                                            <p> {formik.values[item.guid] ? formik.values[item.guid].name : ""}</p>
                                        }
                                        {
                                            Boolean(formik.errors[item.guid]) &&
                                            <FormHelperText error={true}>{formik.errors[item.guid]} </FormHelperText>}

                                    </Box>
                                ))}

                        </Box>
                        <Box sx={styles.bankBox}>
                            <Box
                                component="p"
                                sx={styles.pBank}
                            >פרטי חשבון בנק להעברת הכספים

                                <Box sx={styles.bankDetails}>
                                    {bankDetails.map((item) => {
                                        if (item.id === "bankName")
                                            return (< React.Fragment key={item.id} >
                                                <FormControl
                                                    sx={{ ...styles.Autodetails, width: item.width }}>
                                                    <Autocomplete
                                                        id={item.id}
                                                        name={item.id}
                                                        value={formik.values[item.id]}
                                                        onChange={(e, value) => {
                                                            formik.setFieldValue("bankName", value)
                                                        }}
                                                        options={codes}
                                                        loading={true}
                                                        loadingText="אין אופציות"
                                                        getOptionLabel={type => type.name}
                                                        isOptionEqualToValue={(option, value) => option?.code === value?.code}
                                                        renderInput={(params) => <TextField {...params}
                                                            placeholder={item.name}
                                                            sx={styles.placeholder}
                                                            error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                            helperText={formik.touched[item.id] && formik.errors[item.id]}
                                                        />}
                                                    />
                                                </FormControl>
                                            </React.Fragment>);
                                        return <TextField
                                            key={item.id}
                                            sx={{
                                                ...styles.details,
                                                mt: 6,
                                                width: item.width
                                            }}
                                            id={item.id}
                                            name={item.id}
                                            variant="outlined"
                                            type={item.id}

                                            // placeholder={item.name}
                                            label={item.name}
                                            // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                                            fullWidth
                                            value={formik.values[item.id]}
                                            onChange={item.id === "code" ? ()=>{} : formik.handleChange}
                                            error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                            helperText={formik.touched[item.id] && formik.errors[item.id]}
                                        />
                                    })}
                                </Box>

                            </Box>
                        </Box>
                        <Box sx={styles.commiteeBox}>
                            <Box
                                component="p"
                                sx={styles.pCommitee}
                            >החלטת הוועדה
                            </Box>
                            <Box sx={styles.committeDetails}>
                                {committee.map((item) => {
                                    if (item.id === "paymentMethod") {
                                        return (
                                            <FormControl
                                                key={item.id}
                                                sx={{ width: item.width }}>
                                                <InputLabel id="demo-controlled-open-select-label">אמצעי תשלום</InputLabel>
                                                <Select
                                                    id="paymentMethod"
                                                    name="paymentMethod"
                                                    value={formik.values.paymentMethod}
                                                    sx={{ backgroundColor: "#ffffff", }}
                                                    label="אמצעי תשלום"
                                                    onChange={formik.handleChange}
                                                >

                                                    <MenuItem value={0} sx={{ fontSize: "1.2vw" }} disabled>בחירה מהרשימה</MenuItem>
                                                    <MenuItem value="bank-transfer" sx={{ fontSize: "1.2vw" }}>העברה בנקאית</MenuItem>
                                                    <MenuItem value="voucher" sx={{ fontSize: "1.2vw" }}>המחאה</MenuItem>

                                                </Select>
                                                {formik.touched.paymentMethod &&
                                                    Boolean(formik.errors.paymentMethod) &&
                                                    <FormHelperText error={true}>יש לבחור אמצעי תשלום</FormHelperText>
                                                }
                                            </FormControl>)
                                    }
                                    return <TextField
                                        key={item.id}
                                        sx={{
                                            ...styles.details,
                                            width: item.width
                                        }}
                                        id={item.id}
                                        type={item.id === "decisionDate" ? "date" : ""}
                                        name={item.id}
                                        variant="outlined"
                                        label={item.name}
                                        InputLabelProps={{ ...item.id === "decisionDate" ? { shrink: true } : {} }}
                                        value={formik.values[item.id]}
                                        onChange={formik.handleChange}
                                        error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                        helperText={formik.touched[item.id] && formik.errors[item.id]}
                                    />
                                })}
                            </Box>


                        </Box>
                        <Box sx={styles.bankBox}>
                            <Box
                                component="p"
                                sx={styles.pBank}
                            >סטטוס פניה

                                <Box sx={styles.bankDetails}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={value}
                                            sx={{ width: "100%" }}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel
                                                value="pending"
                                                sx={{ px: 3 }}
                                                control={<Radio sx={styles.radio} />}
                                                label="ממתין" />
                                            <FormControlLabel
                                                value="approved"
                                                sx={{ px: 3 }}
                                                control={<Radio sx={styles.radio} />}
                                                label="מאושר"
                                            />
                                            <FormControlLabel
                                                value="rejected"
                                                sx={{ px: 3 }}
                                                control={<Radio sx={styles.radio} />}
                                                label="לא מאושר"
                                            />
                                            <FormControlLabel
                                                value="pending-masav"
                                                sx={{ px: 3 }}
                                                control={<Radio sx={styles.radio} />}
                                                label='ממתין למס"ב'
                                            />
                                            <FormControlLabel
                                                value="finished"
                                                sx={{ px: 3 }}
                                                control={<Radio sx={styles.radio} />}
                                                label='הסתיים'
                                            />

                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                            </Box>
                        </Box>
                        <Box sx={styles.submitButtons}> 
                        {(role === "admin" || role === "officer") &&
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                form="applicationForm"
                                onClick = {() => setTarget(0)}
                                sx={{ ml: "auto", width: "40%", fontWeight: 700, fontSize: "1.3vw" }}
                            >
                                {isSubmitting ? <CircularProgress size={26} sx={{ color: "#ffffff" }} /> : "עדכון פניה"}
                            </Button>}
                            {(role === "admin" || role === "officer") &&
                            <Button
                                variant="contained"
                                color='inherit'
                                form="applicationForm"
                                onClick = {() =>  {
                                    setOpenPopup(true); }}
                                sx={{ ml: "auto", width: "40%", fontWeight: 700, fontSize: "1.3vw" }}
                            >
                                {isSubmitting ? <CircularProgress size={26} sx={{ color: "#ffffff" }} /> : "שכפל פניה"}
                            </Button>}
                            </Box>


                            { openPopup && <Box                       
                                 sx={styles.popup}
                                >
                                <p>האם אתה מעוניין לשכפל את הפנייה?</p>
                                <Box                            
                                 sx={styles.popupButtons}
                                >
                                <Button
                                 sx={styles.popupButton}

                                onClick={() => {
                                    setOpenPopup(false);
                                }}
                                >
                                חזור
                                </Button>
                                <Button
                                sx={styles.popupButton}

                                onClick={() => {
                                    setOpenPopup(false);
                                    setTarget(1);  
                                    formik.handleSubmit();
                                }}
                                >
                                שכפל
                                </Button>
                                </Box>

                                </Box>
}


                    </Box>
                    </Box>
                }

            </Box >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה תקלה בלתי צפויה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    פניה עודכנה בהצלחה!
                </Alert>
            </Snackbar>}
        </>
    )
})

export default SingleApplication;

