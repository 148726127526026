import { hover } from "@testing-library/user-event/dist/hover";
import theme from "../../themes/default";
const styles = {
    details: {
        "& .MuiOutlinedInput-root": {
            background: "white",
            "& > fieldset": {
                border: "1px solid #797979",
            },
            "& input::placeholder": {
                fontSize: "1.2vw",
                color: " #545353",
                opacity: 1,


            },
            // height: "8vh",
        },

    },
    Autodetails: {
        // width: "100%",
        "& .MuiOutlinedInput-root": {
            background: "white",

            "& > fieldset": {
                border: "1px solid #797979",

            },



        },
        width: "23%",
        mt: 6
    },
    placeholder: {
        "& input::placeholder": {
            fontSize: "1.2vw",
            color: " #545353",
            opacity: 1,


        },
    },
    main: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        py: 6,
        boxSizing: "border-box",
        overflowY: "auto",

    },
    form: {
        width: "70%",
        backgroundColor: "#f2f2f2",
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        p: 4,
        alignItems: "center",
        border: `10px solid ${theme.palette.primary.main} `
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: "2.1vw",
        fontWeight: 700
    },
    formDetails: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        px: 5,
        pb: 0
    },
    p: {
        fontSize: "1.4vw",
        fontWeight: 500,
        color: theme.palette.text.primary,
        mr: "auto",
        mt: 4
    },
    filesBox: {
        display: "flex",
        flexDirection: "column",
        mr: "auto"
    },
    bankBox: {
        m: "auto",
        display: "flex",
        justifyContent: "center"
    },
    commiteeBox: {
        m: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%"
    },
    pBank: {
        fontSize: "1.4vw",
        fontWeight: 500,
        color: theme.palette.text.primary,
        textAlign: "center",
        mt: 4
    },
    pCommitee: {
        fontSize: "1.4vw",
        fontWeight: 500,
        color: theme.palette.text.primary,
        textAlign: "center",
        mt: 4
    },
    bankDetails: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    committeDetails: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around"
    },
    submitButtons:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: '40%'
    },
    popup:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: '30%',
        height: '30%',
        backgroundColor: '#EDEDED',
        position: 'fixed',
        left: '40%',
        top: '50%',
        alignItems: 'center',
        borderRadius: '16px',
        fontWeight: '500',
        fontSize: '22px',
        padding: '10px 20px',
        border: '1px solid',
        zIndex: '9900'


    },
    popupButtons:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: '80%',
    },
    popupButton:{
        backgroundColor: '#D0948A',
        width: '40%',
        color:'white',
        fontSize: '16px',
        fontWeight: '400',
    }

};

export default styles;