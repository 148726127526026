
import { all } from 'redux-saga/effects'
import { reducer as authReducer, Sagas as authSagas } from './auth';
import { reducer as userReducer, Sagas as userSagas } from './users'
import { reducer as categoryReducer, Sagas as categorySagas } from './categories'
import { reducer as applicationReducer, Sagas as applicationSagas } from './applications'
import { reducer as dependentsReducer, Sagas as dependentdSagas } from './dependents'
import { reducer as reportsReducer, Sagas as reportsSagas } from './reports'
import { reducer as statisticsReducer, Sagas as statisticsSagas } from './statistics'
import { combineReducers } from 'redux';




export const getRootReducer = (options) => combineReducers({

    auth: authReducer,
    user: userReducer,
    categories: categoryReducer,
    applications: applicationReducer,
    dependents: dependentsReducer,
    reports: reportsReducer,
    statistics:statisticsReducer
})


//this is runing all this generators parallel
export function* rootSaga() {
    yield all([
        ...authSagas,
        ...userSagas,
        ...categorySagas,
        ...applicationSagas,
        ...dependentdSagas,
        ...reportsSagas,
        ...statisticsSagas

    ])
}
