import theme from "../../themes/default";
import { Box, Typography,Container, Select,MenuItem, CircularProgress } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid,
   Tooltip, Legend,PieChart, Pie,Cell,Label } from 'recharts';
import _ from "lodash";
import { useEffect, useState } from "react";
import { stringify } from "stylis";




export default function Dashboard({loading,monthData,onMount,yearData,getYearGraph,pieData}) {

const [year,setYear]=useState(new Date().getFullYear());
const [YearspieCharts,setYearspieCharts]=useState([])
const [maxYear,setMaxYear]=useState(100000);
const [dataKeys,setDataKeys]=useState([]);
const colors=[
  "#d1958b", 
  "#DC143C",
  "#A52A2A",
  "#CD5C5C",
   "#FF69B4",
  "#F08080",
  "#9370DB",
  "#C71585",
  "#FA8072",
  "#BC8F8F",
  "#EE82EE",
  "#FF1493",
  "#696969",
  "#DB7093",  
]

const [data,setData] = useState([
    {
      name: 'ינואר',
    },
    {
      name: 'פברואר',
    },
    {
      name: 'מרץ',
    },
    {
      name: 'אפריל',
    },
    {
      name: 'מאי',
    },
    {
      name: 'יוני',
   },
    {
      name: 'יולי',
     
    },
    {
        name: 'אוגוסט',
    },
    {
        name: 'ספטמבר',
    },
    {
        name: 'אוקטובר',
    },
    {
      name: 'נובמבר',
    },
    {
        name: 'דצמבר',
    },
  ]);

  useEffect(()=>{
    onMount(new Date().getFullYear())
  },[onMount])
 
  
  useEffect(()=>{
    let categoriesArr=[];
    if(yearData&&yearData.length>0){
      yearData.forEach(function (item,index) {
       if(!_.find(categoriesArr,{datakey:item.categorySubject})){
       categoriesArr.push({datakey:item.categorySubject, datakeyEn:item.categorySubjectEn, color:colors[index % colors.length]})
      }
    })
    setMaxYear(Math.max(...yearData.map(o => o.totalExpenses)
      ))
    }
    setDataKeys(categoriesArr)

    let yearmonth=[...data];
    let i=0;
    categoriesArr.forEach(function(item){
      let match = _.filter(yearData, { 'categorySubject': item.datakey});
      while(yearmonth.length>i){
        let monthSubject=_.find(match,{month: (i + 1).toString()})
        yearmonth[i]={...yearmonth[i],[item.datakey]:monthSubject?monthSubject.totalExpenses:0};
        i++;
      }
     i=0; 
    })
    setData(yearmonth)
  },[yearData])

  useEffect(()=>{
    let data=[[],[],[]]
if(pieData){

  pieData.oneYear.forEach(item => {
    data[0].push({name:getNamePie(item), value:Number(item.totalExpenses)})
  });
  pieData.twoYears.forEach(item => {
    data[1].push({name:getNamePie(item), nameEn:item.categorySubjectEn, value:Number(item.totalExpenses)})
  });
  pieData.threeYears.forEach(item => {
    data[2].push({name:getNamePie(item), nameEn:item.categorySubjectEn, value:Number(item.totalExpenses)})
  });
setYearspieCharts(data)
}
  },[pieData])
    
function getNamePie(item) {

  return ` ${item.categorySubject} ${item.categorySubjectEn? ` | ${item.categorySubjectEn}` : ""}`;
}


function numberWithCommas(x) {
  if(x)
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return x;
}
// console.log("YearspieCharts",YearspieCharts)
let currentYear = new Date().getFullYear(); 
const renderYears=()=>{
  let earliestYear = 2000;   
  let yearArr=[]  
  while (currentYear >= earliestYear) {      
    yearArr.push(currentYear);            
    currentYear -= 1;    
  }
  return yearArr;
}
if(loading)
return(
  <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} />
)
    return (
        <Container maxWidth="xl">
        <Box sx={{ width: "100%",height:"100vh", display: "flex", alignItems: "center",flexDirection:"column",overflowY:"auto",overflowX:"hidden"}}>
              <Box component="p" sx={{ fontSize: "2vw", fontWeight: "700", color: theme.palette.text.primary, mb: "0" }}>כלל ישראל מעשים טובים</Box>
              <Box component="p" sx={{ fontSize: "1.6vw", fontWeight: "600", color: theme.palette.text.primary, mt: "0" }}>clal israel good dreed</Box>
            <Box sx={{display:"flex",width:"80%",justifyContent:"space-between"}}>
                   <ExpensesSummery text={<div style={{ textAlign: 'center' }}>
                      <div dir="rtl">הוצאות החודש</div>
                      <div dir="ltr" style={{ fontSize: '0.8em' }}>this month expenses</div>
                    </div>} number={monthData&&monthData?.oneMonth[0]['totalExpenses']?numberWithCommas(monthData?.oneMonth[0]['totalExpenses']):0}/>
                   <ExpensesSummery text={<div style={{ textAlign: 'center' }}>
                      <div dir="rtl">הוצאות 3 חודשים אחרונים</div>
                      <div dir="ltr" style={{ fontSize: '0.8em' }}>Last 3 months expenses</div>
                    </div>} number={monthData&&monthData?.twoMonths[0]['totalExpenses']?numberWithCommas(monthData?.twoMonths[0]['totalExpenses']):0}/>
                   <ExpensesSummery text={<div style={{ textAlign: 'center' }}>
                      <div dir="rtl">הוצאות 12 חודשים אחרונים</div>
                      <div dir="ltr" style={{ fontSize: '0.8em' }}>last 12 months expenses</div>
                    </div>} number={monthData&&monthData?.twelveMonths[0]['totalExpenses']?numberWithCommas(monthData?.twelveMonths[0]['totalExpenses']):0}/>

            </Box>
            <Box sx={{width:"100%",mt:3,display:"flex",flexDirection:"column",alignItems:"center"}}>
            <Select
              id="yearDropdown"
              value={year}
              sx={{mx:"auto",my:3,width:"150px"}}
              onChange={(e)=>{getYearGraph(e.target.value);setYear(e.target.value);}}
            >
              {renderYears().map((year)=>(
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}

            </Select>
          <LineChart
            width={900}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0,maxYear]} />
            <Tooltip />
            <Legend 
                verticalAlign="top"
                height={36}
                align="right"
                direction="column"
                style={{textAlign:"right"}}
              />
              {dataKeys.map((datakey,index)=>(
                <Line key={` ${datakey.datakey} ${datakey.datakeyEn? ` | ${datakey.datakeyEn}`: ""}`} type="monotone" dataKey={datakey.datakey} stroke={datakey.color} activeDot={{ r: 8 }}/>
              ))}
          </LineChart>
            </Box>
            <Box sx={{width:"100%" ,mt:3,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
             {YearspieCharts&&YearspieCharts.map((item,index)=>{
              if(!item||item.length===0)
              return <></>;
              else return(
                <>
<Typography sx={{fontSize:"2rem",fontWeight:700,color:theme.palette.text.primary}}> התפלגות הוצאות {new Date().getFullYear()-(index+1)}</Typography>
<Typography sx={{fontSize:"1.4rem",fontWeight:600,color:theme.palette.text.primary}}> Distribution of expenses  {new Date().getFullYear()-(index+1)} year</Typography>

                  <PieChart key={index} width={500} height={500}>
<Legend direction="row" verticalAlign="top" align="right" style={{width:"100%"}} /> 
{/* <Legend content={renderLegend} align="center" layout="top" /> */}
            <Pie
              data={item}
              cx="50%"
              cy="50%"
              label={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                value,
                index
              }) => {
                const RADIAN = Math.PI / 180;
                // eslint-disable-next-line
                const radius = 40 + innerRadius + (outerRadius - innerRadius);
                // eslint-disable-next-line
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                // eslint-disable-next-line
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
                return (
                  <text
                    x={x}
                    y={y}
                    fill="#000000"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central"
                  >
                    {numberWithCommas(value)}
                  </text>
                );
              }}
              outerRadius={120}
              fill="#000000"
              dataKey="value"
              style={{padding:6}}
            >
               <Label value={new Date().getFullYear()-(index+1)} position="center" 
              />
      
              {item.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} 
                />
              ))}
            </Pie>
            <Tooltip/>
           </PieChart></>)          
             })}
              </Box>

              </Box>
      
        </Container>
    )
}


export  function ExpensesSummery({number,text}){

  return(
    <Box  sx={{display:"flex",width:"25%",flexDirection:"column",alignItems:"center",justifyContent:"center",border:`5px solid ${theme.palette.primary.main} `,borderRadius:"25px",p:2}}>
    <Typography sx={{fontSize:"1.1rem",fontWeight:700,color: theme.palette.text.primary}}>
      {text}
      </Typography>
    <Typography sx={{fontSize:"2rem",fontWeight:700,opacity:"0.5"}}>{number}</Typography>
    </Box>
  )
}