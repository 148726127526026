import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const applicationReducer = (state = {
    error: false,
    loading: false,
    submitSuccess: false,
    applications: [],
    filter: '',
    application: null,
    isSubmitting: false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.NEW_APPLICATION_REQUEST:
            newState = { ...state, loading: true, error: false, submitSuccess: false }
            break;
        case ActionsTypes.NEW_APPLICATION_SUCCESS:
            newState = { ...state, loading: false, error: false, submitSuccess: true }
            break;
        case ActionsTypes.NEW_APPLICATION_FAILURE:
            newState = { ...state, loading: false, error: true, submitSuccess: false }
            break;
        case PageActionsTypes.APPLICATION_FORM_MOUNTED:
            newState = { ...state, loading: false, error: false, submitSuccess: false };
            break;
        case ActionsTypes.FETCH_APPLICATIONS_REQUEST:
            newState = { ...state, loading: true, error: false, applications: null }
            break;
        case ActionsTypes.FETCH_APPLICATIONS_SUCCESS:
            newState = { ...state, loading: false, applications: payload }
            break;
        case ActionsTypes.FETCH_APPLICATIONS_FAILURE:
            newState = { ...state, loading: false, error: true }
            break;
        case PageActionsTypes.FILTER_SEARCH_APPLICATIONS_SUBMITTED:
            newState = { ...state, filter: `&search=${payload}` }
            break;
        case PageActionsTypes.FILTER_SEARCH_APPLICATIONS_CLEARED:
            newState = { ...state, filter: '' }
            break;
        case ActionsTypes.SINGLE_APPLICATION_REQUEST:
            newState = { ...state, loading: true, error: false, application: null, submitSuccess: false, isSubmitting: false }
            break;
        case ActionsTypes.SINGLE_APPLICATION_SUCCESS:
            newState = { ...state, loading: false, application: payload }
            break;
        case ActionsTypes.FETCH_APPLICATIONS_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.EDIT_APPLICATION_REQUEST:
            newState = { ...state, isSubmitting: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.EDIT_APPLICATION_SUCCESS:
            newState = { ...state, isSubmitting: false, submitSuccess: true, error: false }
            break;
        case ActionsTypes.EDIT_APPLICATION_FAILURE:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: true }
            break;
        case ActionsTypes.EXPORT_MASAV_REQUEST:
            newState = { ...state, isSubmitting: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.EXPORT_MASAV_SUCCESS:
            newState = { ...state, isSubmitting: false, error: false }
            break;
        case ActionsTypes.EXPORT_MASAV_FAILURE:
            newState = { ...state, isSubmitting: false, error: true }
            break;
        case PageActionsTypes.PENDING_MASAV_PAGE_MOUNTED:
            newState = { ...state, isSubmitting: false, error: false }
            break;


        default:
            newState = state;

    }
    return newState;
}
export default applicationReducer;