import { connect } from "../../utils/reduxUtils"
import Component from "./Categories"
import { Actions } from "../../pages/actions"
import * as CategorySelectors from "../../modules/categories/selectors"


const mapDispatchToProps = {
    onMount: Actions.CATEGORIES_PAGE_MOUNTED,
    onSubmit: Actions.APPLICATION_FORM_SUBMITTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED

};

const mapStateToProps = state => {
    return {
        loading: CategorySelectors.isLoading(state),
        error: CategorySelectors.error(state),
        submitSuccess: CategorySelectors.submitSuccess(state),
        categories: CategorySelectors.categories(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



