import { connect } from "../../utils/reduxUtils"
import Component from "./Login"
import * as AuthSelectors from "../../modules/auth/selectors"
import { Actions } from "../../pages/actions"
import { Actions as AuthActions } from '../../modules/auth/actions'

const mapDispatchToProps = {
    onSubmit: Actions.LOGIN_FORM_SUBMITTED,
    // onUnMount: AuthActions.LOGIN_FORM_UNMOUNT
};

const mapStateToProps = state => {
    return {
        loading: AuthSelectors.isLoading(state),
        error: AuthSelectors.loginFailureMessage(state),
        loggedIn: AuthSelectors.isLoggedIn(state),
        sendFailed: AuthSelectors.error(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);