import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [
    'GET_MONTH_STATISTICS_REQUEST',
    'GET_MONTH_STATISTICS_SUCCESS',
    'GET_MONTH_STATISTICS_FAILURE',

    'GET_YEAR_GRAPH_REQUEST',
    'GET_YEAR_GRAPH_SUCCESS',
    'GET_YEAR_GRAPH_FAILURE',
    
    'GET_PIE_CHARTS_REQUEST',
    'GET_PIE_CHARTS_SUCCESS',
    'GET_PIE_CHARTS_FAILURE'
]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);