import { connect } from "../../utils/reduxUtils"
import Component from "./SingleApplication"
import { Actions } from "../../pages/actions"
import * as ApplicationsSelectors from '../../modules/applications/selectors'

const mapDispatchToProps = {
    onSubmitCreate: Actions.APPLICATION_FORM_REPLICATE,
    onSubmitUpdate: Actions.EDIT_APPLICATION_FORM_SUBMITTED,
    onMount: Actions.SINGLE_APPLICATION_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,

};

const mapStateToProps = state => {
    return {
        application: ApplicationsSelectors.application(state),
        loading: ApplicationsSelectors.isLoading(state),
        error: ApplicationsSelectors.error(state),
        submitSuccess: ApplicationsSelectors.submitSuccess(state),
        isSubmitting: ApplicationsSelectors.isSubmitting(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef:true})(Component);