import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [
    'ACTIVE_CATEGORY_REQUEST',
    'ACTIVE_CATEGORY_SUCCESS',
    'ACTIVE_CATEGORY_FAILURE',

    'ALL_CATEGORY_REQUEST',
    'ALL_CATEGORY_SUCCESS',
    'ALL_CATEGORY_FAILURE',

    'SINGLE_CATEGORY_REQUEST',
    'SINGLE_CATEGORY_SUCCESS',
    'SINGLE_CATEGORY_FAILURE',

    'EDIT_CATEGORY_REQUEST',
    'EDIT_CATEGORY_SUCCESS',
    'EDIT_CATEGORY_FAILURE',

    'CREATE_CATEGORY_REQUEST',
    'CREATE_CATEGORY_SUCCESS',
    'CREATE_CATEGORY_FAILURE'



]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);