import { connect } from "../../utils/reduxUtils"
import Component from "./SingleCategory"
import { Actions } from "../../pages/actions"
import * as CategorySelectors from '../../modules/categories/selectors'

const mapDispatchToProps = {
    onSubmit: Actions.EDIT_CATEGORY_FORM_SUBMITTED,
    onMount: Actions.SINGLE_CATEGORY_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    getCategory: Actions.GET_CATEGORY_REQUEST,
    createCategory: Actions.NEW_CATEGORY_SUBMITTED
};

const mapStateToProps = state => {
    return {
        loading: CategorySelectors.isLoading(state),
        error: CategorySelectors.error(state),
        submitSuccess: CategorySelectors.submitSuccess(state),
        isSubmitting: CategorySelectors.isSubmitting(state),
        category: CategorySelectors.category(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);