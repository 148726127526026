import { connect } from "../../utils/reduxUtils"
import Component from "./SingleDependent"
import { Actions } from "../../pages/actions"
import * as DependentSelectors from '../../modules/dependents/selectors'

const mapDispatchToProps = {
    onSubmit: Actions.EDIT_DEPENDENT_FORM_SUBMITTED,
    onMount: Actions.SINGLE_DEPENDENT_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    getInquiries: Actions.GET_DEPENDENT_INQUIRY
};

const mapStateToProps = state => {
    return {
        loading: DependentSelectors.isLoading(state),
        error: DependentSelectors.error(state),
        submitSuccess: DependentSelectors.submitSuccess(state),
        dependent: DependentSelectors.dependent(state),
        data: DependentSelectors.dependentInquiries(state),
        isSubmitting: DependentSelectors.isSubmitting(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);