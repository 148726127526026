import { connect } from "../../utils/reduxUtils"
import Component from "./Users"
import * as UserSelector from "../../modules/users/selectors"
import { Actions } from "../../pages/actions"

const mapDispatchToProps = {
    // onSubmit: Actions.LOGIN_FORM_SUBMITTED,
    onMount: Actions.USERS_PAGE_MOUNTED,
    submitSearch: Actions.FILTER_SEARCH_USERS_SUBMITTED,
    clearSearch: Actions.FILTER_SEARCH_USERS_CLEARED,
    changeTab: Actions.ACTIVE_TAB_CHANGED
};

const mapStateToProps = state => {
    return {
        loading: UserSelector.isLoading(state),
        data: UserSelector.users(state),
        // i: AuthSelectors.loginFailureMessage(state),
        // loggedIn: AuthSelectors.isLoggedIn(state),
        filter: UserSelector.filter(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);