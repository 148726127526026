const accessTokenKey = 'access_token';

export const retrieveAccessToken = async () => {
    return sessionStorage.getItem(accessTokenKey);
}

export const storeAccessToken = async (accessToken) => {
    sessionStorage.setItem(accessTokenKey, accessToken);
}

export const storeName = async (name) => {
    sessionStorage.setItem("userName", name);
}

export const storeRole = async (role) => {
    sessionStorage.setItem("userRole", role);
}



export const clearAccessToken = async () => {
    sessionStorage.removeItem(accessTokenKey);
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


export const parseAccessToken = (token) => {
    try {
        return JSON.parse(b64DecodeUnicode(token.split('.')[1].toString('utf-8')));
    } catch (e) {
        return null;
    }
};


