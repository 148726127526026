import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [

    'FETCH_DEPENDENTS_REQUEST',
    'FETCH_DEPENDENTS_SUCCESS',
    'FETCH_DEPENDENTS_FAILURE',

    'SINGLE_DEPENDENT_REQUEST',
    'SINGLE_DEPENDENT_SUCCESS',
    'SINGLE_DEPENDENT_FAILURE',

    'EDIT_DEPENDENT_REQUEST',
    'EDIT_DEPENDENT_SUCCESS',
    'EDIT_DEPENDENT_FAILURE',

    'DEPENDENT_INQUIRIES_REQUEST',
    'DEPENDENT_INQUIRIES_SUCCESS',
    'DEPENDENT_INQUIRIES_FAILURE'

]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);