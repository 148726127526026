import {
    Box, Button, CircularProgress, Drawer,
    IconButton, TextField, Autocomplete,
    FormControl, Snackbar, Alert
} from '@mui/material'
import styles from './style'
import DataTable from '../../components/DataTable/DataTable'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik'
import { fetchCities } from '../../utils/autoComplete'




export default function SingleDependent({
    onMount,
    loading,
    data,
    changeTab,
    dependent,
    onSubmit,
    isSubmitting,
    error,
    submitSuccess,
    getInquiries
}) {

    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [cities, setCities] = useState([])
    const navigate = useNavigate()
    const [sortedTable, setsortedTable] = useState(data);
    const { id } = useParams();
    const role = sessionStorage.getItem("userRole")

    const details = [
        {
            name: "שם פרטי",
            id: "firstName"
        },
        {
            name: "שם משפחה",
            id: "lastName"
        },
        {
            name: "ת.ז",
            id: "tz_num"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "כתובת מייל",
            id: "email"

        },
        {
            name: "עיר",
            id: "city"
        },
        {
            name: "רחוב",
            id: "street"
        },
        {
            name: "מס' בית",
            id: "houseNo"
        }

    ]

    const formik = useFormik({
        initialValues: {
            firstName: dependent && dependent.firstName ? dependent.firstName : '',
            lastName: dependent && dependent.lastName ? dependent.lastName : '',
            email: dependent && dependent.email ? dependent.email : '',
            phone: dependent && dependent.phone ? dependent.phone : '',
            tz_num: dependent && dependent.tz_num ? dependent.tz_num : '',
            city: dependent && dependent.city ? {
                "שם_ישוב":
                    dependent.city
            } : null,
            street: dependent && dependent.street ? dependent.street : '',
            houseNo: dependent && dependent.houseNo ? dependent.houseNo : '',
        },
        validate: validateInputs,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payload = {
                id: id,
                details: {
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    city: values.city['שם_ישוב'],
                    street: values.street,
                    houseNo: Number(values.houseNo)
                }

            }
            onSubmit(payload)
        },
    });



    const onChangePagination = (newPage) => {
        getInquiries({ page: newPage, id: id })
        setPage(newPage)
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    useEffect(() => {
        setsortedTable(data)
    }, [data])


    useEffect(() => {
        setOpenSnack(true)
        if (error || submitSuccess)
            setOpen(false)
    }, [error, submitSuccess])

    useEffect(() => {
        onMount(id)
        fetchCities().then(c => setCities(c)).catch(console.error);
        getInquiries({ page: 0, id: id })
        changeTab(3)
    }, [])

    const showData = (data) => {
        let showtable = [];
        let date
        let ddate

        if (data.length > 0) {
            data.map((item, index) => {
                date = new Date(item.updatedAt)
                ddate = date.getDate() +
                    "." + (date.getMonth() + 1) +
                    "." + date.getFullYear();
                showtable.push({ ...item });
                showtable[index] = {
                    ...showtable[index],
                    dependentName: item.dependent.firstName + " " + item.dependent.lastName,
                    categoryName: item.category.subject,
                    cityName: item.dependent.city,
                    updatedAt: ddate,
                    statusLabel: item.status === "pending" ? "ממתין" : item.status === "approved" ? "מאושר" :
                        item.status === "pending-masav" ? 'ממתין למס"ב' : item.status === "finished" ?? "הסתיים"
                }
            })
        }
        return showtable;
    }

    let headers = [
        {
            name: "מזהה פניה",
            width: "10%",
            table: "id"
        },
        {
            name: "שם המבקש",
            width: "15%",
            table: "dependentName"
        },
        {
            name: "קטגוריה",
            width: "10%",
            table: "categoryName"
        },
        {
            name: "עיר",
            width: "10%",
            table: "cityName"
        },
        {
            name: "סטטוס פניה",
            width: "10%",
            table: "statusLabel"
        },
        {
            name: "תאריך עדכון",
            width: "10%",
            table: "updatedAt"
        },

    ]


    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={styles.wrap}>
                    <Box sx={styles.actions}>
                        <Button
                            variant="contained"
                            disableRipple
                            onClick={() => { setOpen(true) }}
                            sx={styles.createButton}>
                            פרטי נתמך
                        </Button>

                    </Box>
                    <Box>
                        <Box sx={styles.main}>
                            {loading ?
                                <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> : <>
                                    <Box component="p" sx={{ fontSize: "1.3vw", fontWeight: 700 }}>רשימת פניות {formik.values.firstName} {formik.values.lastName}</Box>
                                    <DataTable
                                        fdata={sortedTable}
                                        showData={showData}
                                        headers={headers}
                                        onChange={onChangePagination}
                                        page={page}
                                        path="/applications"
                                    />
                                </>
                            }
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Drawer
                open={open}
                anchor="right"
                PaperProps={{ style: { padding: "1%", paddingLeft: "unset", overflow: "hidden" } }}

            >
                <Box
                    sx={styles.sideDrawer}
                >
                    <Box sx={styles.header}>

                        <Box
                            component="p"
                            sx={styles.title}
                        >
                            {(role === "admin" || role === "officer") ? "עריכת נתמך" : "פרטי נתמך"}
                        </Box>
                        <IconButton
                            onClick={() => { setOpen(false) }}
                            disableRipple
                            sx={{ ml: "auto" }}
                        >
                            <CancelIcon sx={styles.avatar} />
                        </IconButton>


                    </Box>
                </Box>

                <Box
                    style={styles.children}
                    component="form"
                    id="editDependent"
                    onSubmit={formik.handleSubmit}
                >
                    {details.map((item) => {
                        if (item.id === "city")
                            return < React.Fragment key={item.id} >
                                <FormControl sx={{ width: "95%" }}>
                                    <Autocomplete
                                        id={item.id}
                                        name={item.id}
                                        value={formik.values[item.id]}
                                        onChange={(e, value) => {
                                            formik.setFieldValue("city", value)

                                        }}
                                        options={cities}
                                        loading={true}
                                        loadingText="אין אופציות"
                                        getOptionLabel={type => type['שם_ישוב']}
                                        isOptionEqualToValue={(option, value) => option['שם_ישוב'] === value['שם_ישוב']}
                                        renderInput={(params) => <TextField {...params}
                                            placeholder={item.name}
                                            sx={styles.autoComplete}
                                            error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                            helperText={formik.touched[item.id] && formik.errors[item.id]}
                                        />}
                                    />
                                </FormControl>
                            </React.Fragment>
                        return <TextField
                            key={item.id}
                            sx={{
                                ...styles.textFieldDrawer,
                                // mt: 6,
                                // width: "90%"
                            }}
                            rows={item.id == "subject" ? 5 : 1}
                            multiline
                            id={item.id}
                            name={item.id}
                            variant="outlined"
                            type={item.id}
                            // placeholder={item.name}
                            label={item.name}
                            disabled={item.id === "tz_num" ? true : false}
                            // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                            fullWidth
                            value={formik.values[item.id]}
                            onChange={formik.handleChange}
                            error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                            helperText={formik.touched[item.id] && formik.errors[item.id]}
                        />



                    })}



                </Box>
                <Box
                    // ref={setRef}
                    sx={{ ...styles.buttonsWrap, bottom: "20px" }}

                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",

                        }}

                    >

                        {(role === "admin" || role === "officer") && <Button
                            variant="contained"
                            type="submit"
                            form="editDependent"
                            sx={{ width: "48%", borderRadius: 0 }}
                        >
                            {isSubmitting ? <CircularProgress size={22} sx={{ color: "#ffffff" }} /> : "שמור"}
                        </Button>}

                    </Box>
                </Box>

            </Drawer >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    הפרטים נשמרו בהצלחה
                </Alert>
            </Snackbar>}
        </>
    )
}

const validateInputs = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'יש להזין כתובת מייל';
    } else if (
        !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

    ) {
        errors.email = 'כתובת מייל אינה תקינה';
    }
    if (!values.firstName) {
        errors.firstName = 'יש להזין שם פרטי';
    }
    if (!values.lastName) {
        errors.lastName = 'יש להזין שם משפחה';
    }
    if (!values.phone) {
        errors.phone = "יש להזין מס' טלפון";
    } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = "מספרים בלבד";
    }
    else if (values.phone.length != 10) {
        errors.phone = "מס' לא תקין";
    }
    if (!values.tz_num) {
        errors.tz_num = "יש להזין תעודת זהות";
    } else if (!/^[0-9]*$/.test(values.tz_num)) {
        errors.tz_num = "מספרים בלבד";
    }
    if (!values.city) {
        errors.city = "יש לבחור עיר";
    }
    if (!values.street) {
        errors.street = "יש להזין שם רחוב";
    }
    if (!values.houseNo) {
        errors.houseNo = "יש  להזין מס' בית";
    }
    else if (!/^[0-9]*$/.test(values.houseNo)) {
        errors.houseNo = "מספרים בלבד";
    }
    return errors;
}