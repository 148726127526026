
import { Box, Button, CircularProgress } from '@mui/material'
import styles from './style'
import SearchTextField from '../../components/searchTextField/SearchTextField'
import DataTable from '../../components/DataTable'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Applications({ onMount, submitSearch, clearSearch, loading, data, filter, changeTab, status }) {

    let filterSearch = ""
    const [page, setPage] = useState(0);
    const [sortedTable, setsortedTable] = useState(data);
    const role = sessionStorage.getItem("userRole")
    const navigate=useNavigate()

    // const [searchParams, setSearchParams] = useSearchParams();

    const onChangePagination = (newPage) => {
        onMount({ page: newPage, filter: filter, status: status })
        setPage(newPage)
    }

    useEffect(() => {
        setsortedTable(data)
    }, [data])

    useEffect(() => {
        if (status)
            onMount({ page: 0, filter: filter, status: status })
        changeTab(4)
    }, [status,changeTab,onMount,filter])

    useEffect(() => {
        onMount({ page: 0, filter: filter, status: status });
    }, [filter,onMount,status])


    const showData = (data) => {

        let showtable = [];
        let date
        let ddate

        if (data.length > 0) {
            data.map((item, index) => {
                date = new Date(item.updatedAt)
                ddate = date.getDate() +
                    "." + (date.getMonth() + 1) +
                    "." + date.getFullYear();
                showtable.push({ ...item });
                showtable[index] = {
                    ...showtable[index],
                    dependentName: item.dependent.firstName + " " + item.dependent.lastName,
                    categoryName: item.category?.subject,
                    cityName: item.dependent.city,
                    updatedAt: ddate,
                    statusLabel: item.status === "pending" ? "ממתין" : item.status === "approved" ? "מאושר" :
                        item.status === "pending-masav" ? 'ממתין למס"ב' : item.status === "finished" ?? "הסתיים"
                }
            })
        }
        return showtable;
    }

    let headers = [
        {
            name: "שם המבקש",
            width: "15%",
            table: "dependentName"
        },
        {
            name: "מספר כרטיס",
            width: "10%",
            table: "id"
        },
        {
            name: "קטגוריה",
            width: "10%",
            table: "categoryName"
        },
        {
            name: "עיר",
            width: "10%",
            table: "cityName"
        },
        {
            name: "סטטוס פניה",
            width: "10%",
            table: "statusLabel"
        },
        {
            name: "תאריך עדכון",
            width: "10%",
            table: "updatedAt"
        },

    ]


    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={styles.wrap}>
                <Box sx={styles.actions}>
                {(role === "admin" || role === "officer") && <Button
                        variant="contained"
                        disableRipple
                        onClick={() => { navigate("/") }}
                        sx={styles.createButton}>
                        צור פניה
                    </Button>}
                    <SearchTextField
                        onSearch={() => { submitSearch(filterSearch) }}
                        onEmptySearch={() => { clearSearch() }}
                        width="30%"
                        setSearch={(item) => { filterSearch = item }}
                        placeholder="שם המבקש"
                    />
                </Box>
                <Box>
                    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {loading ?
                            <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> :
                            <DataTable
                                fdata={sortedTable}
                                showData={showData}
                                headers={headers}
                                onChange={onChangePagination}
                                page={page}
                            />
                        }
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}