import queryString from "query-string";
import {
    put,
    call,
    takeLatest,
    fork,
    take,
    cancel,
    delay,
    race,
} from "redux-saga/effects";
import { Sagas } from ".";
import {
    ActionsTypes as DependntsActionTypes,
    Actions as DependntsActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";
import { Navigate } from "react-router-dom";
// import config from 'config';
import _ from "lodash";

function* fetchDependents(action) {
    let { payload, type } = action;
    try {
        if (payload.page == 0) {
            yield put(DependntsActions.FETCH_DEPENDENTS_REQUEST())
        }
        const { data } = yield call(axios.get,
            `dependent?page=${payload.page}&take=10${payload.filter}`);
        yield put(DependntsActions.FETCH_DEPENDENTS_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(DependntsActions.FETCH_DEPENDENTS_FAILURE(err));
    }
}

function* getSingleDependent(action) {
    try {
        const { type, payload } = action;
        yield put(DependntsActions.SINGLE_DEPENDENT_REQUEST());
        const { data } = yield call(axios.get, `/dependent/${payload}`);
        yield put(DependntsActions.SINGLE_DEPENDENT_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(DependntsActions.SINGLE_DEPENDENT_FAILURE(err));
    }
}

function* editDependent(action) {
    try {
        const { type, payload } = action;
        const details = payload.details
        yield put(DependntsActions.EDIT_DEPENDENT_REQUEST());
        const { data } = yield call(axios.put, `/dependent/update/${payload.id}`, {
            firstName: details.firstName,
            lastName: details.lastName,
            phone: details.phone,
            email: details.email,
            street: details.street,
            city: details.city,
            houseNo: details.houseNo
        });
        yield put(DependntsActions.EDIT_DEPENDENT_SUCCESS());
    } catch (err) {
        console.error(err)
        yield put(DependntsActions.EDIT_DEPENDENT_FAILURE(err));
    }
}

function* getDependentInquiries(action) {
    try {
        const { type, payload } = action;
        yield put(DependntsActions.DEPENDENT_INQUIRIES_REQUEST());
        const { data } = yield call(axios.get, `inquiries?take=10&page=${payload.page}&dependentId=${payload.id}`);
        yield put(DependntsActions.DEPENDENT_INQUIRIES_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(DependntsActions.DEPENDENT_INQUIRIES_FAILURE(err));
    }
}






const sagas = [
    takeLatest(PagesActions.DEPENDENTS_PAGE_MOUNTED, fetchDependents),
    takeLatest(PagesActions.SINGLE_DEPENDENT_PAGE_MOUNTED, getSingleDependent),
    takeLatest(PagesActions.GET_DEPENDENT_INQUIRY, getDependentInquiries),
    takeLatest(PagesActions.EDIT_DEPENDENT_FORM_SUBMITTED, editDependent),

];
export default sagas;
