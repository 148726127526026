import { connect } from "../../utils/reduxUtils"
import Component from "./ForgotPassword"
import * as AuthSelectors from "../../modules/auth/selectors"
import { Actions } from "../../pages/actions"
import { Actions as AuthActions } from '../../modules/auth/actions'

const mapDispatchToProps = {
    onSubmit: Actions.FORGOT_PASSWORD_FORM_SUBMITTED,
    onMount: Actions.FORGOT_PASSWORD_PAGE_MOUNTED
};

const mapStateToProps = state => {
    return {
        loading: AuthSelectors.isLoading(state),
        error: AuthSelectors.error(state),
        submitSuccess: AuthSelectors.submitSuccess(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);