import { connect } from "../../utils/reduxUtils"
import Component from "./SingleUser"
import * as UserSelectors from "../../modules/users/selectors"
import { Actions } from "../../pages/actions"


const mapDispatchToProps = {
    onMount: Actions.SINGLE_USER_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    onSubmit: Actions.EDIT_USER_SUBMITTED,
    onDelete: Actions.DELETE_USER_SUBMITTED
};

const mapStateToProps = state => {
    return {
        loading: UserSelectors.isLoading(state),
        error: UserSelectors.error(state),
        submitSuccess: UserSelectors.submitSuccess(state),
        user: UserSelectors.user(state),
        isSubmitting: UserSelectors.isSubmitting(state),
        isDeleting: UserSelectors.isDeleting(state),
        deleteError: UserSelectors.deleteError(state),
        deleteSuccess: UserSelectors.deleteSuccess(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);