import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.auth;
export const isLoggedIn = createSelector(sliceSelector, slice => slice.isLoggedIn);
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const user = createSelector(sliceSelector, slice => slice.user);
export const name = createSelector(sliceSelector, slice => slice.name);
//what is the meaning of this?
export const authComplete = createSelector(sliceSelector, slice => slice.authComplete);
export const loginFailureMessage = createSelector(sliceSelector, slice => slice.loginFailureMessage);
export const isValidate = createSelector(sliceSelector, slice => slice.isValidate);
export const activeTab = createSelector(sliceSelector, slice => slice.activeTab);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
