import { connect } from '../../utils/reduxUtils';
import Component from './App';
import * as AuthSelectors from '../../modules/auth/selectors';

const mapDispatchToProps = {
};

const mapStateToProps = state => {
    return {
        isAuthenticated: AuthSelectors.isLoggedIn(state),
        isAuthComplete: AuthSelectors.authComplete(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);