import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga';
import { getRootReducer, rootSaga } from '../modules'

import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history';

const sagaMiddleware = createSagaMiddleware()


const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    //other options if needed 
});
const initialState = {}
const middleware = [
    sagaMiddleware,
    routerMiddleware,
]

let composeEnhancers = compose;
const enableRedux = process.env.MODE === 'development' || !!sessionStorage.getItem('enableRedux');
if (enableRedux && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const composedEnhancers = composeEnhancers(
    applyMiddleware(...middleware),
)

const store = createStore(
    getRootReducer({ routerReducer }),
    initialState,
    composedEnhancers
)

sagaMiddleware.run(rootSaga);

store.dispatch({ type: 'STORE_LOADED' });

export const history = createReduxHistory(store);

export default store





