import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.categories;
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
export const activeCategories = createSelector(sliceSelector, slice => slice.activeCategories)
export const categories = createSelector(sliceSelector, slice => slice.categories)
export const category = createSelector(sliceSelector, slice => slice.category)
export const isSubmitting = createSelector(sliceSelector, slice => slice.isSubmitting)


