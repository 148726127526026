
const styles = {
    container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
    },
    logotype: {
        display: {
            xs: 'none',
            md: 'flex',
        },
        alignItems: "center",
        marginBottom: theme => theme.spacing(12),
    },
    logotypeText: {
        fontWeight: 500,
        color: "white",
        marginRight: theme => theme.spacing(2),
    },
    logotypeIcon: {
        width: 70,
        marginLeft: theme => theme.spacing(2),
    },
    paperRoot: {
        boxShadow: theme => theme.customShadows.widgetDark,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: theme => theme.spacing(8),
        paddingBottom: theme => theme.spacing(8),
        paddingInlineStart: theme => theme.spacing(6),
        paddingInlineEnd: theme => theme.spacing(6),
        maxWidth: 404,
    },
    textRow: {
        marginBottom: theme => theme.spacing(10),
        textAlign: "center",
        fontSize: "16px"
    },
    errorCode: {
        fontSize: 148,
        fontWeight: 600,
    },

    backButton: {
        boxShadow: theme => theme.customShadows.widget,
        textTransform: "none",
        fontSize: 22,
    },
};

export default styles;