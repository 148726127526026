import SingleApplication from "../singleApplication"
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import { Button ,Stack,Typography,Box} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';


export default function SingleApplicationWrap(){

    const componentRef = useRef();
    const [isPrinting,setIsprinting]=useState(false)
    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });



    return(
       <Box
       sx={{ display: "flex",
       flexDirection:"column",
       justifyContent: "center",
       width: "100%",
       height: "100vh",
       pt: 6,
       pb:{xs:6,xl:20},
       boxSizing: "border-box",
       overflowY: "auto"}} >
        <Button
        variant="contained"
          onClick={handlePrint}
          sx={{mr:"auto",mx:3,width:"100px"}}
           ><Stack
           direction="row"
           justifyContent="space-between"
           alignItems="center"
           sx={{ color: 'text.primary',fontWeight:700 }}
         >
            <PrintIcon/>
         <Typography sx={{ fontSize: '0.8rem', mx: 1 }}>
          הדפס
         </Typography>
         </Stack>
           </Button>
        <SingleApplication ref={componentRef}/>
        
        </Box>
    )
}