import Applications from "../../components/applications/applicationPageRedux"
import { useDispatch } from "react-redux"
import { Actions } from "../actions"
import { useEffect } from "react"

export default function RejectedApplications() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(Actions.ACTIVE_TAB_CHANGED(8))
    }, [dispatch])
    return (
        <Applications status="rejected" />
    )

}