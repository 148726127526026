import { connect } from "../../utils/reduxUtils"
import Component from "./Register"
import { Actions } from "../../pages/actions"
import * as UserSelectors from '../../modules/users/selectors'

const mapDispatchToProps = {
    onSubmit: Actions.REGISTER_FORM_SUBMITTED,
    onMount: Actions.REGISTER_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED
};

const mapStateToProps = state => {
    return {
        loading: UserSelectors.isLoading(state),
        error: UserSelectors.error(state),
        submitSuccess: UserSelectors.submitSuccess(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);