import { connect } from "../../utils/reduxUtils"
import Component from "./ApplicationForm"
import { Actions } from "../../pages/actions"
import * as ApplicationSelectors from "../../modules/applications/selectors"


const mapDispatchToProps = {
    onMount: Actions.APPLICATION_FORM_MOUNTED,
    onSubmit: Actions.APPLICATION_FORM_SUBMITTED

};

const mapStateToProps = state => {
    return {
        loading: ApplicationSelectors.isLoading(state),
        error: ApplicationSelectors.error(state),
        submitSuccess: ApplicationSelectors.submitSuccess(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



