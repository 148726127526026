import React from "react";
import { Grid, Paper, Typography, Button } from '@mui/material';
import { Link } from "react-router-dom";

// styles
import styles from "./style";

export default function Error() {
    return (
        <Grid container sx={styles.container}>
            <Paper sx={styles.paperRoot}>
                <Typography
                    variant="h1"
                    color="primary"
                    sx={{ ...styles.textRow, ...styles.errorCode }}
                >
                    404
                </Typography>
                <Typography variant="h5" color="primary" sx={styles.textRow}>
                    אופס... הדף שאתה מחפש לא קיים
                </Typography>
                {/* change it instead of returning homePage return to previus page */}
                {/* <Typography
                    variant="h6"
                    color="text"
                    sx={{ ...styles.textRow }}
                >
                    אין סיבה לדאגה, ניתן לחזור לדף הבית
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    size="large"
                    sx={styles.backButton}
                >
                    חזרה לדף הבית
                </Button> */}
            </Paper>
        </Grid>
    );
}