import queryString from "query-string";
import {
    put,
    call,
    takeLatest,
    fork,
    take,
    cancel,
    delay,
    race,
} from "redux-saga/effects";
import { Sagas } from ".";
import {
    ActionsTypes as AuthActionTypes,
    Actions as AuthActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";
import {
    storeAccessToken,
    parseAccessToken,
    clearAccessToken,
    retrieveAccessToken,
    storeName,
    storeRole
} from "../../utils/authUtils";
import { Navigate } from "react-router-dom";
// import config from 'config';
import _ from "lodash";

function* loginWithPassword(action) {
    try {
        yield put(AuthActions.LOGIN_REQUEST());
        const { email, password } = action.payload;
        const { data } = yield call(axios.post, `auth/login`, {
            email,
            password,
        });
        const { token } = data;
        const { name } = data;
        const { role } = data;
        if (!token) {
            throw new Error("No access token");
        }
        yield call(storeAccessToken, token);
        yield call(storeName, name)
        yield call(storeRole, role)
        const user = parseAccessToken(token);
        // const user = "user"
        // const name = "rachel"
        yield put(AuthActions.LOGIN_SUCCESS({ user: user, name: name }));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.LOGIN_FAILURE(err));
    }

}

export function* logout(reason) {
    yield call(clearAccessToken);
    localStorage.removeItem("tab")
    sessionStorage.removeItem("userName")
    sessionStorage.removeItem("userRole")
    yield put(AuthActions.LOGOUT_SUCCESS({ reason }));
};

function* consolidateAuth() {
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    if (query.access_token) {
        yield call(storeAccessToken, query.access_token);
    }
    const accessToken = yield call(retrieveAccessToken);
    const user = parseAccessToken(accessToken);
    if (!user || user.exp * 1000 < Date.now()) {
        yield call(logout, "expired");
    } else {
        yield put(AuthActions.LOGIN_SUCCESS(user));
    }
}

function* logoutOn401() {
    while (true) {
        const skippedActions = [AuthActionTypes.LOGIN_FAILURE];
        const action = yield take("*");
        if (skippedActions.includes(action.type)) {
            continue;
        }
        if (action.error && _.get(action.payload, "response.status") === 401) {
            yield fork(logout, "expired");
            // yield delay(config.logoutThrottle)
        }
    }
}

// function* resetPassword(action) {
//     let { payload, type } = action;
//     try {
//         yield put(AuthActions.RESET_PASSWORD_REQUEST())
//         yield put(AuthActions.RESET_PASSWORD_FAILED())
//     }
//     catch (err) {

//     }
// }

function* forgotPassword(action) {
    let { payload, type } = action;
    try {
        yield put(AuthActions.FORGOT_PASSWORD_REQUEST())
        const { data } = yield call(axios.post,
            `/auth/forgot-password`, { email: payload });
        yield put(AuthActions.FORGOT_PASSWORD_SUCCESS())
    }
    catch (err) {
        yield put(AuthActions.FORGOT_PASSWORD_FAILURE())
    }
}

function* resetPassword(action) {
    let { payload, type } = action;
    try {
        yield put(AuthActions.FORGOT_PASSWORD_REQUEST())
        const { data } = yield call(axios.post,
            `auth/reset-password`, {
            email: payload.email,
            resetCode: payload.resetCode,
            password: payload.password,
            confirmPassword: payload.confirmPassword
        });
        yield put(AuthActions.FORGOT_PASSWORD_SUCCESS())
    }
    catch (err) {
        yield put(AuthActions.FORGOT_PASSWORD_FAILURE())
    }
}

const sagas = [
    takeLatest(PagesActions.LOGIN_FORM_SUBMITTED, loginWithPassword),
    takeLatest(PagesActions.SIDEBAR_LOGOUT_REQUESTED, logout, "bySidebar"),
    // takeLatest(PagesActions.REGISTER_FORM_SUBMITTED, registerUser),
    takeLatest(PagesActions.FORGOT_PASSWORD_FORM_SUBMITTED, forgotPassword),
    takeLatest(PagesActions.RESET_PASSWORD_FORM_SUBMITTED, resetPassword),
    // takeLatest(PagesActions.RESET_PASSWORD_SUBMITTED, resetPassword),


    takeLatest("STORE_LOADED", consolidateAuth),
    takeLatest("STORE_LOADED", logoutOn401),
];
export default sagas;
