import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";




const dependentsReducer = (state = {

    dependents: [],
    loading: false,
    error: false,
    isValidate: false,
    submitSuccess: false,
    filter: '',
    dependent: null,
    dependentInquiries: null,
    isSubmitting: false

}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.FETCH_DEPENDENTS_REQUEST:
            newState = { ...state, loading: true, dependents: null }
            break;
        case ActionsTypes.FETCH_DEPENDENTS_SUCCESS:
            newState = { ...state, loading: false, dependents: payload }
            break;
        case ActionsTypes.FETCH_DEPENDENTS_FAILURE:
            newState = { ...state, loading: false, error: true }
            break;
        case PageActionsTypes.FILTER_SEARCH_DEPENDENTS_SUBMITTED:
            newState = { ...state, filter: `&search=${payload}` }
            break;
        case PageActionsTypes.FILTER_SEARCH_DEPENDENTS_CLEARED:
            newState = { ...state, filter: "" }
            break;
        case ActionsTypes.SINGLE_DEPENDENT_REQUEST:
            newState = { ...state, loading: true, dependent: null }
            break;
        case ActionsTypes.SINGLE_DEPENDENT_SUCCESS:
            newState = { ...state, loading: false, dependent: payload }
            break;
        case ActionsTypes.SINGLE_DEPENDENT_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.EDIT_DEPENDENT_REQUEST:
            newState = { ...state, isSubmitting: true, error: false, submitSuccess: false }
            break;
        case ActionsTypes.EDIT_DEPENDENT_FAILURE:
            newState = { ...state, isSubmitting: false, error: true }
            break;
        case ActionsTypes.EDIT_DEPENDENT_SUCCESS:
            newState = { ...state, isSubmitting: false, submitSuccess: true }
            break;
        case PageActionsTypes.SINGLE_DEPENDENT_PAGE_MOUNTED:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: false }
            break;
        case ActionsTypes.DEPENDENT_INQUIRIES_REQUEST:
            newState = { ...state, loading: true, dependentInquiries: null }
            break;
        case ActionsTypes.DEPENDENT_INQUIRIES_SUCCESS:
            newState = { ...state, loading: false, dependentInquiries: payload }
            break;
        case ActionsTypes.DEPENDENT_INQUIRIES_FAILURE:
            newState = { ...state, loading: false }
            break;


        default:
            newState = state;

    }
    return newState;
}
export default dependentsReducer;