import {
    Box, Button, TextField, CircularProgress,
    Snackbar, Alert, Select, MenuItem, FormControl,
    FormHelperText, Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material"
import styles from "./style"
import theme from "../../themes/default"
import { useFormik } from 'formik';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";



export default function SingleUser({ loading, error, submitSuccess,
    onSubmit, onMount, changeTab, user, isSubmitting,
    isDeleting, onDelete, deleteError, deleteSuccess }) {


    const [openSnack, setOpenSnack] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const { id } = useParams();


    const roles = [
        {
            name: "מנהל",
            id: "admin"
        },
        {
            name: "עובד משרד",
            id: "officer"
        },
        {
            name: "מגייס כספים",
            id: "recruiting"
        },
    ]
    const details = [
        {
            name: "שם",
            id: "name"
        },
        {
            name: 'כתובת מייל',
            id: "email"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "תפקיד",
            id: "role"
        }
    ]



    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess, deleteSuccess, deleteError])



    useEffect(() => {
        onMount(id)
        changeTab(2)
    }, [])

    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין כתובת מייל';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.name) {
            errors.name = 'יש להזין שם';
        }
        if (!values.phone) {
            errors.phone = "יש להזין מס' טלפון";
        } else if (!/^[0-9]*$/.test(values.phone)) {
            errors.phone = "מספרים בלבד";
        }
        else if (values.phone.length < 10) {
            errors.phone = "מס' לא תקין";
        }
        if (values.role == 0 || !values.role) {
            errors.role = "יש לבחור תפקיד";
        }
        return errors;
    }


    const formik = useFormik({
        initialValues: {
            name: user ? user.name : '',
            email: user ? user.email : '',
            phone: user ? user.phone : '',
            role: user ? user.role : 0
        },
        validate: validateInputs,
        enableReinitialize: true,
        onSubmit: (values) => {
            onSubmit({ id: id, details: values })
        },
    });

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    function handleClose() {
        // setDeleteItem(null)
        setOpenDialog(false)
    }

    function handleDelete() {
        onDelete(id);
        handleClose()
    }



    return (
        <>
            {loading ? <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> : <Box sx={styles.wrapper}>
                <Box sx={styles.title}
                >
                    <Box sx={styles.paragraph}>
                        כלל ישראל מעשים טובים</Box>
                </Box>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="editUser"
                    sx={styles.loginForm}>
                    <Box sx={styles.connect}>
                        עריכת משתמש</Box>
                    <Box
                        sx={styles.form}>
                        {details.map((item) => {
                            if (item.id == "role") {
                                return (
                                    <FormControl
                                        key={item.id}
                                        sx={{ pt: 2, width: "47%", }}>
                                        <Select
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            sx={{ backgroundColor: "#ffffff" }}
                                            onChange={formik.handleChange}
                                        >

                                            <MenuItem value={0} sx={{ fontSize: "1.2vw" }} disabled>בחירה מהרשימה</MenuItem>
                                            <MenuItem value="admin" sx={{ fontSize: "1.2vw" }}>מנהל</MenuItem>
                                            <MenuItem value="officer" sx={{ fontSize: "1.2vw" }}>עובד משרד</MenuItem>
                                            <MenuItem value="recruiting" sx={{ fontSize: "1.2vw" }}>מגייס</MenuItem>
                                        </Select>
                                        {formik.touched.role &&
                                            Boolean(formik.errors.role) &&
                                            <FormHelperText error={true}>יש לבחור תפקיד</FormHelperText>
                                        }
                                    </FormControl>
                                )
                            }
                            return (
                                <TextField
                                    key={item.id}
                                    sx={{ ...styles.details, pt: 2 }}
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id === "email" ? "email" : item.id === "phone" ? "phone" : ""}
                                    placeholder={item.name}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            )
                        })}
                        <Box sx={{ width: "100%", m: "auto", pt: 2, display: "flex", justifyContent: "space-between" }}>

                            <Button
                                variant="contained"
                                form="editUser"
                                type="submit"
                                disabled={deleteSuccess ? true : false}
                                sx={styles.submit}
                            >
                                {isSubmitting ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "שמור"}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={styles.delete}
                                onClick={() => {
                                    // setDeleteItem({ id: item.id, type: "prayerType" });
                                    setOpenDialog(true)
                                }}
                                disabled={deleteSuccess || (user && user.role === "admin") ? true : false}

                            >
                                {isDeleting ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "מחיקת משתמש"}
                            </Button>

                        </Box>
                    </Box>
                    <Box>

                    </Box>

                </Box>

            </Box >}

            {
                (error || deleteError) &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה. נסה שוב
                    </Alert>

                </Snackbar>)}

            {(submitSuccess || deleteSuccess) && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    {submitSuccess ? "משתמש עודכן בהצלחה" : "משתמש נמחק בהצלחה"}
                </Alert>
            </Snackbar>}

            {/* {de && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    משתמש עודכן בהצלחה
                </Alert>
            </Snackbar>} */}

            <Dialog
                open={openDialog}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: "17%",
                        maxHeight: 300
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    color="primary"
                    sx={styles.dialogTitle}>
                    {"מחיקת משתמש"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={styles.content}>
                        האם אתה בטוח שברצונך למחוק משתמש זה?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={styles.actionBox}>
                    <Button
                        variant="outlined"
                        disableRipple
                        onClick={() => {
                            // setDeleteItem(null);
                            handleClose()
                        }}
                        sx={{ borderRadius: 0, px: 4 }}
                    >ביטול</Button>
                    <Button
                        variant="contained"
                        disableRipple
                        onClick={handleDelete}
                        sx={{ borderRadius: 0, px: 4 }}
                        autoFocus>
                        מחיקה
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}