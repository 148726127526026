import { connect } from "../../utils/reduxUtils"
import Component from "./Dependents"
import { Actions } from "../actions"
import * as DependetSelector from '../../modules/dependents/selectors'


const mapDispatchToProps = {
    onMount: Actions.DEPENDENTS_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    submitSearch: Actions.FILTER_SEARCH_DEPENDENTS_SUBMITTED,
    clearSearch: Actions.FILTER_SEARCH_DEPENDENTS_CLEARED

};

const mapStateToProps = state => {
    return {
        filter: DependetSelector.filter(state),
        data: DependetSelector.dependents(state),
        loading: DependetSelector.isLoading(state),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);