import defaultTheme from "./default";

import { createTheme } from '@mui/material/styles';


const overrides = {
    typography: {

        fontFamily: "-apple - system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans- serif",
        h1: {
            fontSize: "24px",
            fontWeight: "bold",
        },
        h2: {
            fontWeight: "700",
            fontSize: "1.8rem",
        },
        h3: {
            fontSize: "1.64rem",
        },
        h4: {
            fontSize: "1.5rem",
        },
        h5: {
            fontSize: "1.285rem",
        },
        h6: {
            color: '#000000',
            opacity: '64%',
            fontSize: '12px',
        },
    },
};

const obj = {
    default: createTheme({ ...defaultTheme, ...overrides, direction: 'rtl' }),
};

export default obj;
