import axios from "axios";
import { retrieveAccessToken } from "../utils/authUtils";

const baseURL = process.env.REACT_APP_SERVER;

const axiosInstance = axios.create({
  baseURL,
  // only 50MB
  maxContentLength: 50000000,
  maxBodyLength: 50000000,
});

axiosInstance.interceptors.request.use(async (request) => {
  const accessToken = await retrieveAccessToken();
  request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
});

export default axiosInstance;
