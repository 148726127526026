import React, { useEffect, useState } from 'react'
import {
    Box, TextField,
    Button,
    Autocomplete,
    FormControl,
    FormHelperText,
    CircularProgress,
    Snackbar, Alert,
    Dialog
} from '@mui/material'
import theme from '../../themes/default'
import { useFormik } from 'formik'
import styles from './style'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fetchCities, fetchBankCodes, fetchCategories } from '../../utils/autoComplete'
import logo from '../../images/logo.png'



export default function Applicationform({ onMount, onSubmit, loading, error, submitSuccess }) {

    // assignment
    const [cities, setCities] = useState([])
    const [codes, setCodes] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [openSnack, setOpenSnack] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false);


    // useEffects
    useEffect(() => {
        fetchCities().then(c => setCities(c)).catch(console.error);
        fetchBankCodes().then(c => setCodes(c)).catch(console.error);
        fetchCategories().then(c => setCategories(c)).catch(console.error)

    }, [])

    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    useEffect(() => {
        onMount()
    }, [])






    const details = [
        {
            name: "שם פרטי",
            id: "firstName"
        },
        {
            name: "שם משפחה",
            id: "lastName"
        },
        {
            name: 'ת.ז',
            id: "tz_num"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "כתובת מייל",
            id: "email"
        },
        {
            name: "עיר",
            id: "city"
        },
        {
            name: "רחוב",
            id: "street"
        },
        {
            name: "מס' בית",
            id: "houseNo"
        },
        {
            name: "קטגוריה",
            id: "category"
        },
        {
            name: "נושא הפניה",
            id: "subject"
        },

    ]

    const bankDetails = [
        {
            name: "בנק",
            id: "bankName",
            width: { xs: "100%", md: "25%" }
        },
        {
            name: "מספר בנק",
            id: "code",
            width: { xs: "100%", md: "13%" }
        },
        {
            name: "מספר חשבון",
            id: "accountNumber",
            width: { xs: "100%", md: "20%" }
        },
        {
            name: "סניף",
            id: "branch",
            width: { xs: "100%", md: "10%" }
        },
        {
            name: 'ע"ש',
            id: "ownerName",
            width: { xs: "100%", md: "20%" }
        },
    ]

    const handleselectedFile = (event) => {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            formik.setFieldValue(event.target.id, {
                url: file,
                file: event.target.files[0],
                name: event.target.files[0].name,
            })
        }
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    useEffect(()=>{
        if(submitSuccess){
            formik.resetForm()
        }
    },
    [submitSuccess])    
    
    function is_israeli_id_number(id) {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            return Array.from(id, Number).reduce((counter, digit, i) => {
                const step = digit * ((i % 2) + 1);
                return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0;
    }
    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין כתובת מייל';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.firstName) {
            errors.firstName = 'יש להזין שם פרטי';
        }
        if (!values.lastName) {
            errors.lastName = 'יש להזין שם משפחה';
        }
        if (!values.phone) {
            errors.phone = "יש להזין מס' טלפון";
        } else if (!/^[0-9]*$/.test(values.phone)) {
            errors.phone = "מספרים בלבד";
        }
        else if (values.phone.length !== 10) {
            errors.phone = "מס' לא תקין";
        }
        if (!values.tz_num) {
            errors.tz_num = "יש להזין תעודת זהות";
        } else if (!/^[0-9]*$/.test(values.tz_num)) {
            errors.tz_num = "מספרים בלבד";
        }
        else if (values.tz_num.length !== 9) {
            errors.tz_num = "מס' לא תקין";
        }
        else if(!is_israeli_id_number(values.tz_num)){
            errors.tz_num = "מס' לא תקין";
        }
        if (!values.city) {
            errors.city = "יש לבחור עיר";
        }
        if (!values.street) {
            errors.street = "יש להזין שם רחוב";
        }
        if (!values.houseNo) {
            errors.houseNo = "יש  להזין מס' בית";
        }
        else if (!/^[0-9]*$/.test(values.houseNo)) {
            errors.houseNo = "מספרים בלבד";
        }
        if (!values.category) {
            errors.category = "יש לבחור קטגורית תמיכה";
        }
        if (!values.subject) {
            errors.subject = "יש להזין נושא לפניה";
        }
        if (!values.bankName) {
            errors.bankName = "יש להזין שם בנק";
        }
        if (!values.code) {
            errors.code = "יש להזין קוד בנק";
        }
        if (!values.accountNumber) {
            errors.accountNumber = "יש להזין מס' חשבון";
        }
        else if (!/^[0-9]*$/.test(values.accountNumber)) {
            errors.accountNumber = "מספרים בלבד";
        }
        if (!values.branch) {
            errors.branch = "יש להזין מס' סניף";
        }
        else if (!/^[0-9]*$/.test(values.branch)) {
            errors.branch = "מספרים בלבד";
        }
        if (!values.ownerName) {
            errors.ownerName = "יש להזין שם בעל החשבון' ";
        }
        if (selectedCategory && selectedCategory.fileTypes) {
            selectedCategory.fileTypes.forEach(function (item) {
                if (!values[item.guid])
                    errors[item.guid] = "לא נבחר קובץ"
            })
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            tz_num: '',
            city: null,
            street: '',
            houseNo: '',
            category: null,
            subject: '',
            bankName: null,
            code: '',
            branch: '',
            accountNumber: '',
            ownerName: '',

        },
        validate: validateInputs,
        onSubmit: (values) => {

            let files = {}
            if (selectedCategory && selectedCategory.fileTypes) {
                selectedCategory.fileTypes.forEach(function (item) {
                    files = { ...files, [item.guid]: values[item.guid] }

                })
            }


            let details = {
                category: values.category.id,
                description: values.subject,
                dependent: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone,
                    email: values.email,
                    tz_num: values.tz_num,
                    city: values.city['שם_ישוב'],
                    street: values.street,
                    houseNo: Number(values.houseNo)
                },
                bankAccount: {
                    code: values.code,
                    branch: values.branch,
                    accountNumber: values.accountNumber,
                    ownerName: values.ownerName
                },
                files: files
            }
            onSubmit(details);
        },
    });

    useEffect(() => {
        if (formik.values.bankName)
            formik.setFieldValue("code", formik.values.bankName.code);
        else {
            formik.setFieldValue("code", "");
        }
    }, [formik.values.bankName])


    return (
        <>
          <Dialog open={alertOpen}>
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setAlertOpen(false);
          }}
        >
          ישנם שדות לא תקינים ביצירת הפניה
        </Alert>
      </Dialog>
            <Box sx={styles.main}>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="applicationForm"
                    sx={styles.form}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-start", alignItems: "center", mb: 2 }}>
                        <Box
                            component="img"
                            src={logo}
                            alt="logo"
                            sx={{ maxWidth: { md: "7vw", xs: "30px" } }}
                        />

                    </Box>

                    <Box sx={styles.title}>
                        טופס פניה-כלל ישראל מעשים טובים
                    </Box>

                    <Box sx={styles.formDetails}>
                        {details.map((item) => {
                            if (item.id === "city")
                                return (< React.Fragment key={item.id} >
                                    <FormControl sx={styles.Autodetails}>
                                        <Autocomplete
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            onChange={(e, value) => {
                                                formik.setFieldValue("city", value)

                                            }}
                                            options={cities}
                                            loading={true}
                                            loadingText="אין אופציות"
                                            getOptionLabel={type => type['שם_ישוב']}
                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder={item.name}
                                                sx={styles.placeholder}
                                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                                            />}
                                        />
                                    </FormControl>
                                </React.Fragment>);
                            else if (item.id === "category")
                                return (< React.Fragment key={item.id} >
                                    <FormControl sx={styles.Autodetails}>
                                        <Autocomplete
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            onChange={(e, value) => {
                                                formik.setFieldValue("category", value)
                                                setSelectedCategory(value);
                                            }}
                                            options={categories}
                                            loading={true}
                                            loadingText="אין אופציות"
                                            getOptionLabel={type => type['subject']}
                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder={item.name}
                                                sx={styles.placeholder}
                                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                                            />}
                                        />
                                    </FormControl>
                                </React.Fragment>);

                            return (
                                <TextField
                                    key={item.id}
                                    sx={{
                                        ...styles.details,
                                        mt: 6,
                                        width: { xs: "100%", md: item.id == "subject" ? "100%" : "23%" }
                                    }}
                                    rows={item.id === "subject" ? 5 : 1}
                                    multiline
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id}
                                    // placeholder={item.name}
                                    label={item.name}
                                    // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            )
                        })}
                        {selectedCategory && selectedCategory.fileTypes.length>0 && <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Box component="p" sx={styles.p}>
                                {"קבצים"}
                            </Box>

                         <Box component="p" sx={{ mt: 0, color: "red" }}>
                                סוגי קבצים מותרים: jpeg, jpg, png, pdf
                            </Box>
                        </Box>}
                    </Box>
                    <Box sx={styles.filesBox}>
                        {selectedCategory ? selectedCategory.fileTypes.map((item) => (
                            <Box sx={{ display: "flex", mb: 1, flexDirection: "column", width: "100%" }}>
                                <input
                                    key={item.guid}
                                    type="file"
                                    name="file"
                                    id={item.guid}
                                    onChange={handleselectedFile}
                                    style={{ display: "none" }}
                                />
                                <p>{item.label}</p>
                                <label htmlFor={item.guid}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{ width: { xs: "100%", md: "15vw" } }}
                                        endIcon={<UploadFileIcon />}
                                    >
                                        {formik.values[item.guid] ? formik.values[item.guid].name : "בחר קובץ"}
                                    </Button>
                                </label>


                                {
                                    Boolean(formik.errors[item.guid]) &&
                                    <FormHelperText error={true}>{formik.errors[item.guid]} </FormHelperText>}

                            </Box>
                        )) :
                            <Box
                                component="p"
                            >בחר קטגוריה להצגת הקבצים הנדרשים
                            </Box>}

                    </Box>
                    <Box sx={styles.bankBox}>
                        <Box
                            component="p"
                            sx={styles.pBank}
                        >פרטי חשבון בנק להעברת הכספים
                        </Box>

                        <Box sx={styles.bankDetails}>
                            {bankDetails.map((item) => {
                                if (item.id == "bankName")
                                    return (< React.Fragment key={item.id} >
                                        <FormControl
                                            sx={{ ...styles.Autodetails, width: item.width }}>
                                            <Autocomplete
                                                id={item.id}
                                                name={item.id}
                                                value={formik.values[item.id]}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("bankName", value)
                                                    if (formik.values.bankName) {
                                                        formik.setFieldValue("bankName", null)
                                                    }
                                                }}
                                                options={codes}
                                                loading={true}
                                                loadingText="אין אופציות"
                                                getOptionLabel={type => type.name}
                                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                                renderInput={(params) => <TextField {...params}
                                                    placeholder={item.name}
                                                    sx={styles.placeholder}
                                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                                />}
                                            />
                                        </FormControl>
                                    </React.Fragment>);
                                return <TextField
                                    key={item.id}
                                    sx={{
                                        ...styles.details,
                                        mt: 6,
                                        width: item.width
                                    }}
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id}

                                    // placeholder={item.name}
                                    label={item.name}
                                    // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={item.id == "code" ? {} : formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            })}
                        </Box>


                    </Box>
                    <Box sx={styles.submit}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            form="applicationForm"
                            sx={styles.sButton}
                            onClick={() => {
                                if (Object.keys(formik.errors).length > 0) {
                                  setAlertOpen(true);
                                }
                              }}
                        >
                            {loading ? <CircularProgress size={26} sx={{ color: "#ffffff" }} /> : "שלח"}
                        </Button>
                    </Box>

                </Box>

            </Box >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    פניה נוצרה בהצלחה!
                </Alert>
            </Snackbar>}
        </>
    )
}