import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.dependents;
export const filter = createSelector(sliceSelector, slice => slice.filter);
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const dependents = createSelector(sliceSelector, slice => slice.dependents);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
export const dependent = createSelector(sliceSelector, slice => slice.dependent);
export const dependentInquiries = createSelector(sliceSelector, slice => slice.dependentInquiries);
export const isSubmitting = createSelector(sliceSelector, slice => slice.isSubmitting);
