import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.user;
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
export const filter = createSelector(sliceSelector, slice => slice.filter)
export const users = createSelector(sliceSelector, slice => slice.users)
export const user = createSelector(sliceSelector, slice => slice.user)
export const isSubmitting = createSelector(sliceSelector, slice => slice.isSubmitting)
export const isDeleting = createSelector(sliceSelector, slice => slice.isDeleting)
export const deleteError = createSelector(sliceSelector, slice => slice.deleteError)
export const deleteSuccess = createSelector(sliceSelector, slice => slice.deleteSuccess)





