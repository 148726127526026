import theme from "../../themes/default";
const styles = {
    wrap: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        py: 3,
        px: 5,
        boxSizing: "border-box",
    },
    actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mb: "2%"
    },
    createButton: {
        width: "12%",
        borderRadius: 0,
        ml: "auto",
        p: 1,
        fontSize: "1vw",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    avatar: {
        width: 24,
        height: 24,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.text.primary,

    },
    children: {
        display: "flex",
        width: "25vw",
        flexDirection: "column",
        alignItems: "flex-start",
        overflowY: "auto",
        overflowX: "hidden",
        marginBottom: "15vh",
        marginTop: "60px",
        px: "2%",
        pt: "5%",
    },
    subTitle: {
        my: 0,
        fontSize: "0.8rem",
        color: "#969FA2"
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: "bold",
        fontSize: "1.4vw",
        m: 0,
        alignSelf: "flex-end"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        pb: 3,
        pl: 2,
        boxSizing: "border-box"
    },
    sideDrawer: {
        position: "absolute",
        top: 0,
        // px: "2%",
        right: 0,
        left: 0,
        backgroundColor: "white",
        mb: "5%",
        zIndex: 99,
        justifyContent: "flex-end",
    },
    buttonsWrap: {
        position: "absolute",
        height: "10vh",
        px: "2%",
        right: 0,
        left: 0,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        zIndex: 99,
        alignItems: "center",
        justifyContent: "space-between"
    },
    stepper: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    textFieldDrawer: {
        width: "95%",
        my: "3%",
        "& .MuiOutlinedInput-notchedOutline legend": { display: "none", },
        "& .MuiOutlinedInput-root": {
            // background: "#F4F6F6",
            borderRadius: 1,
            "& > fieldset": {
                border: "1px solid #D4D4D4",

            },

        },
        "& label": {

            fontWeight: 500,
            // float: "right",
            mt: "2%",
            color: "#969FA2"
        },

        '& placeholder': {
            color: '#455154',
        },
    },
    autoComplete: {
        width: "100%",
        my: "2%",
        "& .MuiOutlinedInput-notchedOutline legend": { display: "none", },

        "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            // height: 50,
            color: "#000000",
            "& > fieldset": {
                border: "1px solid #D4D4D4",
            },
            "& input::placeholder": {
                fontWeight: 500,
                opacity: 1
            },
        },
        "& label": {
            fontWeight: 500,
            // float: "right",
            mt: "2%",
            color: "#969FA2",
            shrink: true
        },
    },
    main: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }

};

export default styles;