import theme from "../../themes/default";
const style = {

    logoWrapper: {
        pt: '10%',
        pb: "7%",
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: "1.4vw",
        textAlign: 'center',
        height: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    logo: {
        width: '100%',
        maxWidth: "9vw",
    },
    avatar: {
        width: 24,
        height: 24,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        border: `0.1px solid ${theme.palette.primary.main} `
    },
    userName: {
        color: theme.palette.text.primary,
        fontSize: "1vw"
    },
    sideBar: {
        marginTop: "auto",
        display: "flex",
        pb: "4%",
        pr: "4%"
    },
    logged: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
};

export default style;
