import _ from 'lodash';
import axios from '../services/axios';

export const fetchCities = async () => {
    const response = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=2000`)
    const data = await response.json()
    const result = data.result.records
    const array = result.map(row => _.pick(row, 'שם_ישוב'))
    return array;
}

export const fetchBankCodes = async () => {
    const res = await axios.get(`/bank-codes`);
    return res.data;
}


export const fetchCategories = async () => {
    const res = await axios.get(`/inquiry-categories`);
    console.log("res", res)
    return res.data;
}