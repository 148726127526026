import {
    put,
    call,
    takeLatest,
} from "redux-saga/effects";
import {
    Actions as StatisticActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";

// import config from 'config';


function* getmonthStatistics(action) {
    try {

        yield put(StatisticActions.GET_MONTH_STATISTICS_REQUEST())
        const {data} = yield call(axios.get,
            `dashboard/month-stats`)

        yield put(StatisticActions.GET_MONTH_STATISTICS_SUCCESS(data));
    }
    catch (err) {
        console.error(err)
            yield put(StatisticActions.GET_MONTH_STATISTICS_FAILURE());
    }
}

function* getYearGraph(action) {
    let { payload} = action;
    try {

        yield put(StatisticActions.GET_YEAR_GRAPH_REQUEST())
        const {data} = yield call(axios.get,
            `dashboard/year-graph/${payload}`)

        yield put(StatisticActions.GET_YEAR_GRAPH_SUCCESS(data));
    }
    catch (err) {
        console.error(err)
            yield put(StatisticActions.GET_YEAR_GRAPH_FAILURE());
    }
}


function* getpieCharts(action) {
    try {

        yield put(StatisticActions.GET_PIE_CHARTS_REQUEST())
        const {data} = yield call(axios.get,
            `dashboard/pie-charts`)

        yield put(StatisticActions.GET_PIE_CHARTS_SUCCESS(data));
    }
    catch (err) {
        console.error(err)
            yield put(StatisticActions.GET_PIE_CHARTS_FAILURE());
    }
}


const sagas = [
    takeLatest(PagesActions.DASHBOARD_PAGE_MOUNTED, getmonthStatistics),
    takeLatest(PagesActions.DASHBOARD_PAGE_MOUNTED, getYearGraph),
    takeLatest(PagesActions.DASHBOARD_PAGE_MOUNTED, getpieCharts),
    takeLatest(PagesActions.DASHBOARD_NEW_YEAR_SUBMITTED, getYearGraph),

];
export default sagas;
