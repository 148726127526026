import { connect } from "../../utils/reduxUtils"
import Component from "./Reports"
import { Actions } from "../../pages/actions"
import * as CategorySelector from '../../modules/categories/selectors'
import * as ReportSelector from '../../modules/reports/selectors'

const mapDispatchToProps = {
    onSubmit: Actions.REGISTER_FORM_SUBMITTED,
    onMount: Actions.REPORTS_PAGE_MOUNTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    onSubmit: Actions.EXPORT_CSV_SUBMITTED
};

const mapStateToProps = state => {
    return {
        isSubmitting: ReportSelector.isSubmitting(state),
        errorMessage: ReportSelector.errorMessage(state),
        categories: CategorySelector.categories(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);