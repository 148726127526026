import { connect } from "../../utils/reduxUtils"
import Component from "./Applications"
import * as ApplicationsSelector from "../../modules/applications/selectors"
import { Actions } from "../../pages/actions"

const mapDispatchToProps = {
    changeTab: Actions.ACTIVE_TAB_CHANGED,
    onMount: Actions.APPLICATIONS_PAGE_MOUNTED,
    submitSearch: Actions.FILTER_SEARCH_APPLICATIONS_SUBMITTED,
    clearSearch: Actions.FILTER_SEARCH_APPLICATIONS_CLEARED,
};

const mapStateToProps = state => {
    return {
        loading: ApplicationsSelector.isLoading(state),
        data: ApplicationsSelector.applications(state),
        filter: ApplicationsSelector.filter(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);