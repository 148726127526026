import { createActionCreators, createActionTypes } from "../../utils/reduxUtils"

const typesActions = [

    'REGISTER_REQUEST',
    'REGISTER_SUCCESS',
    'REGISTER_FAILURE',

    'FETCH_USERS_REQUEST',
    'FETCH_USERS_SUCCESS',
    'FETCH_USERS_FAILURE',

    'SINGLE_USER_REQUEST',
    'SINGLE_USER_SUCCESS',
    'SINGLE_USER_FAILURE',

    'EDIT_USER_REQUEST',
    'EDIT_USER_SUCCESS',
    'EDIT_USER_FAILURE',

    'DELETE_USER_REQUEST',
    'DELETE_USER_SUCCESS',
    'DELETE_USER_FAILURE'


]
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);