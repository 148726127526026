import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";


const userReducer = (state = {
    error: false,
    loading: false,
    registerFailureMessage: false,
    submitSuccess: false,
    isDeleting: false,
    deleteSuccess: false,
    deleteError: false,
    filter: '',
    users: [],
    user: null,
    isSubmitting: false
}, action) => {
    let newState;
    let { payload, type } = action;
    switch (type) {
        case ActionsTypes.REGISTER_REQUEST:
            newState = { ...state, loading: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.REGISTER_SUCCESS:
            newState = { ...state, loading: false, submitSuccess: true }
            break;
        case ActionsTypes.REGISTER_FAILURE:
            newState = { ...state, loading: false, error: true }
            break;
        case PageActionsTypes.REGISTER_PAGE_MOUNTED:
            newState = { ...state, loading: false, error: false, submitSuccess: false }
            break;
        case ActionsTypes.FETCH_USERS_REQUEST:
            newState = { ...state, loading: true, users: null }
            break;
        case ActionsTypes.FETCH_USERS_SUCCESS:
            newState = { ...state, loading: false, users: payload }
            break;
        case ActionsTypes.FETCH_USERS_FAILURE:
            newState = { ...state, loading: false, error: true }
            break;
        case PageActionsTypes.FILTER_SEARCH_USERS_SUBMITTED:
            newState = { ...state, filter: `&search=${payload}` }
            break;
        case PageActionsTypes.FILTER_SEARCH_USERS_CLEARED:
            newState = { ...state, filter: `` }
            break;
        case ActionsTypes.SINGLE_USER_REQUEST:
            newState = { ...state, loading: true, error: false, submitSuccess: false, user: null }
            break;
        case ActionsTypes.SINGLE_USER_SUCCESS:
            newState = { ...state, loading: false, error: false, user: payload }
            break;
        case ActionsTypes.SINGLE_USER_FAILURE:
            newState = { ...state, loading: false }
            break;
        case ActionsTypes.EDIT_USER_REQUEST:
            newState = { ...state, isSubmitting: true, submitSuccess: false, error: false }
            break;
        case ActionsTypes.EDIT_USER_SUCCESS:
            newState = { ...state, isSubmitting: false, submitSuccess: true, error: false }
            break;
        case ActionsTypes.EDIT_USER_FAILURE:
            newState = { ...state, isSubmitting: false, submitSuccess: false, error: true }
            break;
        case ActionsTypes.DELETE_USER_REQUEST:
            newState = { ...state, isDeleting: true, deleteError: false, deleteSuccess: false }
            break;
        case ActionsTypes.DELETE_USER_SUCCESS:
            newState = { ...state, isDeleting: false, deleteError: false, deleteSuccess: true }
            break;
        case ActionsTypes.DELETE_USER_FAILURE:
            newState = { ...state, isDeleting: false, deleteError: true, deleteSuccess: false }
            break;
        case PageActionsTypes.SINGLE_USER_PAGE_MOUNTED:
            newState = { ...state, isDeleting: false, deleteError: false, deleteSuccess: false, isSubmitting: false, submitSuccess: false, error: false }
            break;

        default:
            newState = state;

    }
    return newState;
}
export default userReducer;